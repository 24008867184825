import { createContext, useContext, useReducer } from "react";
import { initState } from "./ReleaseContextInit";
import MasterData from "../Pages/MasterData/MasterData";

interface IReleaseInspectionContext {
  state: any;
  dispatch: any;
}

const init: IReleaseInspectionContext = {
  state: {},
  dispatch: null,
};

const ReleaseInspectionContext = createContext<IReleaseInspectionContext>(init);

const updateLatestData = (state: any, inspection: any, action: any) => {
  let obj = { ...inspection };
  let masterData: any = {
    picturesInspection: {
      positionOfTrailer: obj?.picturesInspection?.positionOfTrailer
        ? obj?.picturesInspection?.positionOfTrailer
        : [],
    },
    tyresInspection: {
      trailer: obj?.tyresInspection?.trailer
        ? obj?.tyresInspection?.trailer
        : [],
    },
    reeferInspection: {
      task: obj?.reeferInspection?.task ? obj?.reeferInspection?.task : [],
    },
    visualInspection: {
      task: obj?.visualInspection?.task ? obj?.visualInspection?.task : [],
    },
    ctpatInspection: {
      task: obj?.ctpatInspection?.task ? obj?.ctpatInspection?.task : [],
    },
    lightInspection: {
      task: obj?.lightInspection?.task ? obj?.lightInspection?.task : [],
    },
  };
  if (
    action.payload.picturesInspection !== null &&
    action.payload.picturesInspection !== undefined
  ) {
    let picInsp = action?.payload?.picturesInspection?.positionOfTrailer?.map(
      (item: any) => {
        let obj = { ...item };
        obj.description = "";
        obj.taskFullName = item.position;
        obj.images = [];
        return obj;
      }
    );
    if (picInsp?.length) {
      masterData.picturesInspection.positionOfTrailer = [...picInsp];
    }
  }
  if (
    action.payload.tyresInspection !== null &&
    action.payload.tyresInspection !== undefined
  ) {
    let tireInsp = action?.payload?.tyresInspection?.trailer?.map(
      (item: any) => {
        const obj = { ...item };
        obj.attachments = {
          attachmentUploads: [],
          attachmentDamageDescription: "",
        };
        return obj;
      }
    );
    if (tireInsp?.length) {
      masterData.tyresInspection.trailer = [...tireInsp];
    }
  }
  if (
    action.payload.reeferInspection !== null &&
    action.payload.reeferInspection !== undefined
  ) {
    let referInsp = action?.payload?.reeferInspection?.task?.map((ele: any) => {
      let obj = { ...ele };
      if (obj.taskName === "Temp Control") {
        obj.taskStatus = "Discharge";
      }
      if (obj.taskName === "Run Mode") {
        obj.taskStatus = "Continuous";
      }
      if (obj.taskName === "Fuel Level") {
        obj.attachments = {
          attachmentDamageDescription: "",
          attachmentUploads: [],
        };
      }
      return obj;
    });

    if (referInsp?.length) {
      masterData.reeferInspection.task = [...referInsp];
    }
  }
  if (
    action.payload.visualInspection !== null &&
    action.payload.visualInspection !== undefined
  ) {
    const vInsp = action?.payload?.visualInspection?.task?.map((item: any) => {
      const obj = { ...item };
      obj.attachments = {
        attachmentUploads: [],
        attachmentDamageDescription: "",
      };
      return obj;
    });
    if (vInsp?.length) {
      masterData.visualInspection.task = [...vInsp];
    }
  }
  if (
    action.payload.ctpatInspection !== null &&
    action.payload.ctpatInspection !== undefined
  ) {
    masterData.ctpatInspection.task = action?.payload?.ctpatInspection?.task;
  }
  if (
    action.payload.lightInspection !== null &&
    action.payload.lightInspection !== undefined
  ) {
    const ligInsp = action?.payload?.lightInspection?.task?.map((item: any) => {
      const obj = { ...item };
      obj.attachments = {
        attachmentUploads: [],
        attachmentDamageDescription: "",
      };
      return obj;
    });
    if (ligInsp?.length) {
      masterData.lightInspection.task = [...ligInsp];
    }
  }
  obj = { ...masterData };
  state.ctpatInspection.task = [...obj.ctpatInspection.task];
  state.lightInspection.task = [...obj.lightInspection.task];
  state.picturesInspection.positionOfTrailer = [
    ...obj.picturesInspection.positionOfTrailer,
  ];
  state.reeferInspection.task = [...obj.reeferInspection.task];
  state.tyresInspection.trailer = [...obj.tyresInspection.trailer];
  state.visualInspection.task = [...obj.visualInspection.task];
  return obj;
};

const updateMasterData = (inspection: any, action: any) => {
  let obj = { ...inspection };
  let masterData: any = {
    picturesInspection: {
      positionOfTrailer: obj?.picturesInspection?.picturesInspection
        ? obj?.picturesInspection?.picturesInspection
        : [],
    },
    tyresInspection: {
      trailer: obj?.tyresInspection?.trailer
        ? obj?.tyresInspection?.trailer
        : [],
    },
    reeferInspection: {
      task: obj?.reeferInspection?.task ? obj?.reeferInspection?.task : [],
    },
    visualInspection: {
      task: obj?.visualInspection?.task ? obj?.visualInspection?.task : [],
    },
    ctpatInspection: {
      task: obj?.ctpatInspection?.task ? obj?.ctpatInspection?.task : [],
    },
    lightInspection: {
      task: obj?.lightInspection?.task ? obj?.lightInspection?.task : [],
    },
  };
  if (action?.payload?.pictureInspection?.tasks?.length) {
    masterData.picturesInspection.positionOfTrailer =
      action?.payload?.pictureInspection?.tasks.map((item: any) => {
        let obj = { ...item };
        obj.damageType = "";
        obj.position = item.taskName;
        obj.images = [];
        obj.description = "";
        return obj;
      });
  }
  if (action?.payload?.ctpatInspection?.tasks?.length) {
    masterData.ctpatInspection.task = action?.payload?.ctpatInspection?.tasks;
  }
  if (action?.payload?.lightInspection?.tasks?.length) {
    masterData.lightInspection.task = action?.payload?.lightInspection?.tasks;
  }
  if (action?.payload?.reeferInspection?.tasks?.length) {
    let refInsp = { ...masterData.reeferInspection.task };
    refInsp = refInsp?.task?.map((items: any) => {
      let obj = { ...items };
      let isMatch = action?.payload?.reeferInspection?.tasks.find(
        (item: any) => item.taskId === obj.taskId
      );
      if (isMatch) {
        if (isMatch.taskName === "Fuel Level") {
          isMatch.attachments = {
            attachmentDamageDescription: "",
            attachmentUploads: [],
          };
        }
        obj = { ...isMatch };
      }
      masterData.reeferInspection.task = obj;
    });
  }
  if (action?.payload?.visualInspection?.tasks?.length) {
    masterData.visualInspection.task = action?.payload?.visualInspection?.tasks;
  }

  if (action?.payload?.tyresInspection?.trailer?.length) {
    masterData.tyresInspection.trailer =
      action?.payload?.tyresInspection?.trailer;
  }

  obj = { ...masterData };
  return obj;
};

const updateRefeerInspection = (inspection: any, payload: any) => {
  let refInsp = { ...inspection };
  refInsp = refInsp?.task?.map((items: any) => {
    let obj = { ...items };
    let isMatch = payload.task?.find((item: any) => item.taskId === obj.taskId);
    if (isMatch) {
      if (isMatch.taskName === "Fuel Level") {
        isMatch.attachments = {
          attachmentDamageDescription: "",
          attachmentUploads: [],
        };
      }
      obj = { ...isMatch };
    }
    return obj;
  });

  return refInsp;
};

const deleteFileAttachments = (inspection: any, payload: any) => {
  try {
    let vinspection = { ...inspection };

    if (payload.category === "ctpatInspection") {
      vinspection.sealImages = vinspection.sealImages
        ? { ...vinspection.sealImages }
        : {};

      vinspection.sealImages.images = vinspection.sealImages.images
        ? [...vinspection.sealImages.images]
        : [];
      vinspection.sealImages.images.push(payload.attachmentUploads);
    }
  } catch (e) {}

  return inspection;
};

const updateSignature = (sign: any, payload: any) => {
  if (sign?.length === 0) {
    sign[0] = "";
    sign[1] = "";
  }
  const signObj = [...sign];
  if (payload.type === 0) {
    signObj[0] = payload.path;
  } else {
    signObj[1] = payload.path;
  }
  return signObj;
};

const deleteSignature = (sign: any, payload: any) => {
  const signObj = [...sign];
  signObj[payload.index] = "";
  return signObj;
};

const updateCTPATSealImages = (inspection: any, payload: any) => {
  try {
    let ctpatInspection = { ...inspection };
    ctpatInspection.sealImages = ctpatInspection.sealImages
      ? { ...ctpatInspection.sealImages }
      : {};

    ctpatInspection.sealImages.images = ctpatInspection.sealImages.images
      ? [...ctpatInspection.sealImages.images]
      : [];

    ctpatInspection.sealImages.description = ctpatInspection.sealImages
      .description
      ? ctpatInspection.sealImages.description
      : "";

    if (payload?.images) {
      ctpatInspection.sealImages.images.push(payload.images);
    } else {
      ctpatInspection.sealImages.description = payload.description;
    }
    return ctpatInspection;
  } catch (e) {
    console.log(e);
  }
};

const updateTyresData = (inspection: any, payload: any) => {
  try {
    let tireinspection = { ...inspection };
    if (payload?.position) {
      let isExists = false;
      tireinspection.trailer = tireinspection?.trailer?.map((item: any) => {
        let obj = { ...item };
        if (obj.position === payload.position) {
          isExists = true;
          obj.condition = payload.condition;
          obj.size = payload.size;
          obj.threadDepth = payload.threadDepth;
          obj.isOriginal = payload.isOriginal;
          obj.isRecap = payload.isRecap;
          obj.brand = payload.brand;
          let attachments: any = item?.attachments
            ? JSON.parse(JSON.stringify(item?.attachments))
            : {
                attachmentUploads: [],
                attachmentDamageDescription: null,
              };

          if (payload?.attachments?.attachmentUploads) {
            let isDuplicate = false;
            // attachments.attachmentUploads.map((item: any) => {
            //   const value = Array.isArray(payload?.attachments?.attachmentUploads) ? payload?.attachments?.attachmentUploads[0] : payload?.attachments?.attachmentUploads;
            //   if(item.fullFilePath === value.fullFilePath)
            //     isDuplicate = true;
            // })
            if (!isDuplicate) {
              if (Array.isArray(payload?.attachments?.attachmentUploads)) {
                attachments.attachmentUploads = [
                  ...payload.attachments.attachmentUploads,
                ];
              } else {
                attachments.attachmentUploads.push(
                  payload.attachments.attachmentUploads
                );
              }
            }
          }
          if (payload?.attachments?.attachmentDamageDescription !== null) {
            attachments.attachmentDamageDescription =
              payload.attachments.attachmentDamageDescription;
          }
          obj.attachments = attachments;
        }
        return obj;
      });

      if (!isExists) {
        tireinspection.trailer.push(payload);
      }
    } else {
      tireinspection = { ...tireinspection, ...payload };
    }

    return tireinspection;
  } catch (e) {}

  return inspection;
};
const updatePictureInspection = (inspection: any, payload: any) => {
  try {
    let pinspection = { ...inspection };
    if (payload.position) {
      pinspection.positionOfTrailer = pinspection?.positionOfTrailer?.map(
        (item: any) => {
          let obj = { ...item };
          if (item.position === payload.position) {
            obj.position = item.position ? item.position : "";
            obj.images = item.images ? [...item.images] : [];
            obj.damageType = item.damageType ? item.damageType : "";
            obj.description = item.description ? item.description : "";
            let images: any = obj?.images;
            obj.position = payload.position;
            if (payload.images) {
              images.push(payload.images);
              obj.images = images;
            } else {
              if (payload.damageType) {
                obj.damageType = payload.damageType;
              } else if (payload.description !== null) {
                obj.description = payload.description;
              }
            }
          }
          return obj;
        }
      );
    } else {
      pinspection = { ...pinspection, ...payload };
    }

    return pinspection;
  } catch (e) {}

  return inspection;
};

const updateReeferInspection = (inspection: any, payload: any) => {
  try {
    let reeferInspection = { ...inspection };
    if (payload?.taskId) {
      reeferInspection.task = reeferInspection?.task?.map((item: any) => {
        let obj = { ...item };
        obj.status = item.status ? item.status : "";
        obj.taskStatus = item.taskStatus ? item.taskStatus : "";
        let attachments: any = item?.attachments
          ? JSON.parse(JSON.stringify(item?.attachments))
          : {
              attachmentUploads: [],
              attachmentDamageDescription: null,
            };

        if (obj.taskId === payload.taskId) {
          if (payload.status !== undefined) {
            obj.status = payload.status;
            return obj;
          } else if (payload.taskStatus !== undefined) {
            obj.taskStatus = payload.taskStatus;
            return obj;
          } else if (payload?.attachments?.attachmentUploads) {
            attachments.attachmentUploads.push(
              payload.attachments.attachmentUploads
            );
          } else if (
            payload?.attachments?.attachmentDamageDescription !== null
          ) {
            attachments.attachmentDamageDescription =
              payload.attachments.attachmentDamageDescription;
          }

          obj.attachments = attachments;
        }
        return obj;
      });
    } else {
      reeferInspection = { ...reeferInspection, ...payload };
    }

    return reeferInspection;
  } catch (e) {}

  return inspection;
};

const updateAttachments = (inspection: any, payload: any) => {
  try {
    let vinspection = { ...inspection };
    if (payload.taskId) {
      vinspection.task = vinspection.task.map((item: any) => {
        let obj = { ...item };
        if (obj.taskId === payload.taskId) {
          if (payload.taskStatus) {
            obj.taskStatus = payload.taskStatus;
          }
          let attachments: any = item?.attachments
            ? JSON.parse(JSON.stringify(item?.attachments))
            : {
                attachmentUploads: [],
                attachmentDamageDescription: null,
              };

          if (payload?.attachments?.attachmentUploads) {
            attachments.attachmentUploads.push(
              payload.attachments.attachmentUploads
            );
          }
          if (payload?.attachments?.attachmentDamageDescription !== null) {
            attachments.attachmentDamageDescription =
              payload.attachments.attachmentDamageDescription;
          }
          obj.attachments = attachments;
        }
        return obj;
      });
    } else {
      vinspection = { ...vinspection, ...payload.attachments };
    }

    return vinspection;
  } catch (e) {}

  return inspection;
};

const updateVisualInspByTaskId = (visualInspection: any, newData: any) => {
  let vinspection = { ...visualInspection };
  if (newData.taskId) {
    vinspection.task = vinspection?.task?.map((item: any) => {
      let obj = { ...item };
      if (obj.taskId === newData.taskId) {
        obj = { ...obj, ...newData };
      }
      return obj;
    });
  } else {
    vinspection = { ...vinspection, ...newData };
  }

  return vinspection;
};

const reducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case "visualInspection":
      return {
        ...state,
        visualInspection: updateVisualInspByTaskId(
          state?.visualInspection,
          action.payload
        ),
      };
    case "lightInspection":
      return {
        ...state,
        lightInspection: updateVisualInspByTaskId(
          state?.lightInspection,
          action.payload
        ),
      };
    case "tyresInspection":
      return {
        ...state,
        tyresInspection: updateTyresData(
          state?.tyresInspection,
          action.payload
        ),
      };
    case "picturesInspection":
      return {
        ...state,
        picturesInspection: updatePictureInspection(
          state?.picturesInspection,
          action.payload
        ),
      };
    case "ctpatInspection":
      return {
        ...state,
        ctpatInspection: updateVisualInspByTaskId(
          state?.ctpatInspection,
          action.payload
        ),
      };
    case "ctpatSealImage":
      return {
        ...state,
        ctpatInspection: updateCTPATSealImages(
          state?.ctpatInspection,
          action.payload
        ),
      };
    case "reeferInspection":
      return {
        ...state,
        reeferInspection: updateReeferInspection(
          state?.reeferInspection,
          action.payload
        ),
      };
    case "addattachments":
      return {
        ...state,
        [action.payload.category]: updateAttachments(
          state[action.payload.category],
          action.payload
        ),
      };
    case "deleteImage":
      return {
        ...state,
        [action.payload.category]: deleteFileAttachments(
          state[action.payload.category],
          action.payload
        ),
      };
    case "comments":
      return {
        ...state,
        comments: action.payload,
      };

    case "UPDATELATESTDATA":
      return {
        ...state,
        masterData: updateLatestData(state, state?.masterData, action),
      };
    case "UPDATEMASTERDATA":
      return {
        ...state,
        masterData: updateMasterData(state?.masterData, action),
        // reeferInspection: {
        //   task:
        //     action?.payload?.reeferInspection !== null &&
        //     action?.payload?.reeferInspection?.task?.length
        //       ? updateRefeerInspection(
        //           state.reeferInspection,
        //           action?.payload?.reeferInspection
        //         )
        //       : action?.payload?.reeferInspection?.tasks?.length
        //       ? action?.payload?.reeferInspection?.tasks?.map((ele: any) => {
        //           let obj = { ...ele };
        //           if (obj.taskName === "Temp Control") {
        //             obj.taskStatus = "Discharge";
        //           }
        //           if (obj.taskName === "Run Mode") {
        //             obj.taskStatus = "Continuous";
        //           }
        //           return obj;
        //         })
        //       : state.reeferInspection.task,
        // },
        // ctpatInspection: {
        //   task:
        //     action?.payload?.ctpatInspection !== null &&
        //     action?.payload?.ctpatInspection?.task
        //       ? action?.payload?.ctpatInspection?.task
        //       : action?.payload?.ctpatInspection?.tasks
        //       ? action?.payload?.ctpatInspection?.tasks
        //       : null,
        // },

        // picturesInspection: {
        //   positionOfTrailer:
        //     (action?.payload?.picturesInspection !== null &&
        //       action?.payload?.picturesInspection?.positionOfTrailer?.map(
        //         (item: any) => {
        //           let obj = { ...item };
        //           obj.description = "";
        //           obj.taskFullName = item.position;
        //           obj.images = [];
        //           return obj;
        //         }
        //       )) ||
        //     (action?.payload?.pictureInspection?.tasks !== null &&
        //       action?.payload?.pictureInspection?.tasks?.map((item: any) => {
        //         let obj = { ...item };
        //         obj.description = "";
        //         obj.taskFullName = item.position;
        //         obj.images = [];
        //         return obj;
        //       })),
        // },
        // visualInspection: {
        //   task:
        //     action?.payload?.visualInspection !== null &&
        //     action?.payload?.visualInspection?.task
        //       ? action?.payload?.visualInspection?.task?.map((item: any) => {
        //           const obj = { ...item };
        //           obj.attachments = {
        //             attachmentUploads: [],
        //             attachmentDamageDescription: "",
        //           };
        //           return obj;
        //         })
        //       : action?.payload?.visualInspection?.tasks
        //       ? action?.payload?.visualInspection?.tasks?.map((item: any) => {
        //           const obj = { ...item };
        //           obj.attachments = {
        //             attachmentUploads: [],
        //             attachmentDamageDescription: "",
        //           };
        //           return obj;
        //         })
        //       : null,
        // },
        // tyresInspection: {
        //   trailer:
        //     action?.payload?.tyresInspection?.trailer !== null &&
        //     action?.payload?.tyresInspection?.trailer?.map((item: any) => {
        //       const obj = { ...item };
        //       obj.attachments = {
        //         attachmentUploads: [],
        //         attachmentDamageDescription: "",
        //       };
        //       return obj;
        //     }),
        // },
        // lightInspection: {
        //   task:
        //     action?.payload?.lightInspection !== null &&
        //     action?.payload?.lightInspection?.task
        //       ? action?.payload?.lightInspection?.task?.map((item: any) => {
        //           const obj = { ...item };
        //           obj.attachments = {
        //             attachmentUploads: [],
        //             attachmentDamageDescription: "",
        //           };
        //           return obj;
        //         })
        //       : action?.payload?.lightInspection?.tasks
        //       ? action?.payload?.lightInspection?.tasks?.map((item: any) => {
        //           const obj = { ...item };
        //           obj.attachments = {
        //             attachmentUploads: [],
        //             attachmentDamageDescription: "",
        //           };
        //           return obj;
        //         })
        //       : null,
        // },
      };
    case "OPEN_SHOWDETAILS_POPUP":
      return {
        ...state,
        opendetailsPopup: action.payload,
      };
    case "SHOWDETAILS":
      return {
        ...state,
        detailsPopup: action.payload,
      };
    case "setEventId":
      return {
        ...state,
        eventId: action.payload,
      };
    case "setEventCode":
      return {
        ...state,
        eventCode: action.payload,
      };
    case "setTaskId":
      return {
        ...state,
        taskID: action.payload,
      };
    case "setPosition":
      return {
        ...state,
        position: action.payload,
      };
    case "setFilename":
      return {
        ...state,
        fileName: action.payload,
      };
    case "addComments":
      return {
        ...state,
        comments: action.payload,
      };
    case "loadStatus":
      return {
        ...state,
        loadStatus: action.payload,
      };
    case "addSignature":
      return {
        ...state,
        signature: updateSignature(state?.signature, action.payload),
      };
    case "deleteSignature":
      return {
        ...state,
        signature: deleteSignature(state?.signature, action.payload),
      };
    case "isReferRequired":
      return {
        ...state,
        reeferInspectionRquired: action.payload,
      };
    case "isOpen":
      return {
        ...state,
        isOpen: action.payload,
      };
    case "signType":
      return {
        ...state,
        signType: action.payload,
      };
    case "clearData":
      return {
        ...initState,
        picturesInspection: {
          ...state.picturesInspection,
          isCompleted: false,
          positionOfTrailer: state?.picturesInspection?.positionOfTrailer?.map(
            (position: any) => ({
              ...position,
              images: [],
              description: "", // Clear images array for each position
            })
          ),
        },
        tyresInspection: {
          ...state.tyresInspection,
          isCompleted: false,
          trailer: [],
        },
        loadStatus: false,
        eventCode: null,
        eventId: null,
      };
    default:
      return state;
  }
};

export const ReleaseInspectionProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const dataObj: any = { state, dispatch };
  return (
    <ReleaseInspectionContext.Provider value={dataObj}>
      {children}
    </ReleaseInspectionContext.Provider>
  );
};

export const useReleaseInspectorContext = () => {
  return useContext(ReleaseInspectionContext);
};
