import React, { useEffect, useRef, useState } from "react";
import DetectDevice from "../../utils/DetectDevice";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import MGrid from "../../Components/MGrid";
import AGGrid from "../../Components/AGGrid";
import { NetworkManager } from "../../Components/NetworkManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { Events } from "../Languages/Events";
import { Button } from "react-bootstrap/lib/InputGroup";
import ToastManager from "../../Components/ToastManager";
let selectedOrgId:any = {};

const Consolidatedinvoice = () => {
  const { isMobile } = DetectDevice();

  const [eqpColumnDefs] = useState([
    {
      field: "batchNumber",
      headerName: "Batch Number",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
      // pinned: "left",
    },
    {
      field: "counts",
      headerName: "Total Invoice",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
      // pinned: "left",
    },
    // {
    //   field: "billStartDate",
    //   headerName: "Bill Start Date",
    //   resizable: true,
    //   width: 100,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "billEndDate",
    //   headerName: "Bill End Date",
    //   resizable: true,
    //   width: 150,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "billedDays",
    //   headerName: "Billed Days",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },

    // {
    //   field: "lastOdometerReading",
    //   headerName: "Last Odometer Reading",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // // {
    // //   field: "billedTotalDistance",
    // //   headerName: "Billed Total Distance",
    // //   resizable: true,
    // //   width: 200,
    // //   sortable: true,
    // //   filter: "agNumberColumnFilter",
    // // },
    // {
    //   field: "totalPlatformFees",
    //   headerName: "Platform Fees",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "adminFees",
    //   headerName: "adminFees",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "totalDailyAmount",
    //   headerName: "Total Daily Amount",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "totalLiabilityInsurance",
    //   headerName: "Total Liability Insurance",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "totalPhysicalInsurance",
    //   headerName: "Total Physical Insurance",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    // // {
    // //   field: "totalReeferAmount",
    // //   headerName: "Total Reefer Amount",
    // //   resizable: true,
    // //   width: 200,
    // //   sortable: true,
    // //   filter: "agNumberColumnFilter",
    // // },
    // {
    //   field: "totalTaxes",
    //   headerName: "Total Taxes",
    //   resizable: true,
    //   width: 200,
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "batchStatus",
      headerName: "Batch Status",
      resizable: true,
      width: 200,
      sortable: true,
      // cellRenderer:(params:any)=>{
      //   const onClickItem = (e:any)=>{
      //       downloadPDF();
      //   }
      //   return (<>
      //     {params?.value && <div className={"cellRenderHeader"}>
      //       <a><span onClick={onClickItem} className={"cellRender"}>{"Retry"}</span></a>
      //   </div>}
      //   </>
      //   )
      // },
      // pinned: "right",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "download",
      headerName: "Download",
      resizable: true,
      width: 200,
      sortable: true,
      cellRenderer: (params: any) => {
        const onClickItem = (e: any) => {
          downloadPDF(params.data.batchId);
        };
        return (
          <div className={"cellRenderHeader"}>
            <a>
              <span
                onClick={onClickItem}
                className={"cellRender"}
                style={{ fontSize: "20px" }}
              >
                <i className="fa-solid fa-download"></i>
              </span>
            </a>
          </div>
        );
      },
      // pinned: "right",
    },
    {
      field: "action",
      headerName: "Action",
      resizable: true,
      width: 200,
      sortable: true,
      cellRenderer: (params: any) => {
        const onClickItem = (e: any) => {
          downloadPDF(params.data.batchId);
        };
        return (
          <div className={"cellRenderHeader"}>
            <button
              className="btn btn-primary small"
              disabled={params?.data?.batchStatus === "Fully Paid"}
              onClick={() => retryPayment(params?.data)}
            >
              Retry Payment
            </button>
          </div>
        );
      },
      // pinned: "right",
    },
  ]);
  const [equipData, setEquipData] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orgData, setOrgData] = useState<any>([]);
  const [selCompanyObj, setSelCompanyObj] = useState<any>({});
  const gridRef = useRef<any>(null);

  const getAllStatementsForOrg = () => {
    let qParam = selectedOrgId || CommonUtil.getSelectedOrgId();
    // api/reservation/findAllBatches?saasOrgId=22
    let usrUrl = `api/reservation/findAllBatches?saasOrgId=${qParam}`;
    NetworkManager.sendJsonRequest(
      usrUrl,
      "",
      "",
      "GET",
      true,
      onSuccessgetAllStatementsFororg,
      onFailgetAllStatementsFororg
    );
  };

  const retryPayment = (data: any) => {
    const { saasOrgId, batchId } = data;
    const getCrediDetailsAPI: string = `api/organization/getFinance?saasOrgId=${saasOrgId}`;
    NetworkManager.sendJsonRequest(
      getCrediDetailsAPI,
      "",
      "",
      "GET",
      true,
      (resp: any) => onGetFinanceInfo(resp, batchId),
      onErrorbyGettingFinanceInfo
    );
  };

  const onGetFinanceInfo = (resp: any, batchId: any) => {
    const response = JSON.parse(String(resp));
    if (response?.ccId && batchId) {
      retryCreditCardPayment(response, batchId);
    } else {
      ToastManager.showToast(`Credit Card details are not found.`, "Error");
    }
  };

  const onErrorbyGettingFinanceInfo = (err: any) => {
    console.log(err, "ERROR");
  };

  const retryCreditCardPayment = (resp: any, batchId: any) => {
    const { ccId } = resp;
    const getPaymentSuccessAPI: string = `api/reservation/makePayment/${batchId}?cardId=${ccId}`;
    NetworkManager.sendJsonRequest(
      getPaymentSuccessAPI,
      "",
      "",
      "POST",
      true,
      (resp: any) => onSuccessfullyPaid(resp),
      onErrorbyPayment
    );
  };

  const onSuccessfullyPaid = (resp: any) => {
    console.log(resp, "KKKKKK");
    const newResp = JSON.parse(String(resp));
    if (newResp?.errorMessage) {
      ToastManager.showToast(
        newResp?.errorMessage
          ? newResp?.errorMessage
          : "Something went wrong please try again.",
        "Error"
      );
    } else {
      getAllStatementsForOrg();
      ToastManager.showToast(
        resp?.Status ? resp?.Status : "Successfully payment done.",
        "Success"
      );
    }
  };

  const onErrorbyPayment = (err: any) => {
    console.log(err, "MMMMM");

    ToastManager.showToast(
      err?.errormessage
        ? err?.errormessage
        : "Something went wrong please try again.",
      "Failed"
    );
  };

  const downloadPDF = async (batchId: any) => {
    if (CommonUtil.getSelectedOrgId()) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      if (localStorage.getItem("token")) {
        var token = "Bearer " + localStorage.getItem("token");
        myHeaders.append("Authorization", token);
      }

      let qParam = selectedOrgId || CommonUtil.getSelectedOrgId();
      await fetch(
        NetworkManager.serverPath +
          `api/download/consolidatedInvoices?saasOrgId=${qParam}&batchId=${batchId}`,
        { method: "GET", headers: myHeaders }
      )
        .then((response: any) => {
          response.blob().then((blob: any) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `consolidatedInvoices.pdf`;
            a.click();
          });
        })
        .catch((error) => {});
    }
  };

  const onSuccessgetAllStatementsFororg = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          setEquipData(restData);
          let tot = 0;
          restData?.map(({ totalAmount }: any) => (tot += Number(totalAmount)));
          setTotalAmount(tot);
        }
      }
    }
  };

  const onFailgetAllStatementsFororg = () => {};

  useEffect(() => {
    if (CommonUtil.isRootUser()) {
      CommonUtil.getAllOrgs((data: any) => {
        let arr = [{ orgId: null, orgName: "All" }];
        setOrgData([...arr, ...data]);
        data?.map((ele: any) => {
          if (CommonUtil.getSelectedOrgId() == ele.orgId) {
            setSelCompanyObj(ele);
            selectedOrgId=ele.orgId
          }
        });
      });
    } else {
      let arr = [
        {
          orgId: CommonUtil.getSelectedOrgId(),
          orgName: CommonUtil.getSelectedOrgName(),
        },
      ];
      setOrgData([...arr]);
      setSelCompanyObj({
        orgId: CommonUtil.getSelectedOrgId(),
        orgName: CommonUtil.getSelectedOrgName(),
      });
      selectedOrgId=CommonUtil.getSelectedOrgId();
    }

    getAllStatementsForOrg();
    document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    return () => {
      document.removeEventListener(
        Events.SWITCH_CUSTOMER_EVENT,
        onSwitchCustomer
      );
    };
  }, []);

  const onSwitchCustomer = (e: any) => {
    if (CommonUtil.isRootUser()) {
      CommonUtil.getAllOrgs((data: any) => {
        let arr = [{ orgId: null, orgName: "All" }];
        setOrgData([...arr, ...data]);
      });
    }
    // getAllStatementsForOrg();
    Events.dispatchBreadCrumbEvent();
  };
  const onCompanyChange = (dataObj: any) => {
    setSelCompanyObj(dataObj);
    selectedOrgId = dataObj.orgId;
  };

  return (
    <>
      <div
        className="container-fluid container-fluid-padding"
        style={{ paddingTop: "15px" }}
      >
        <div className="row ">
          {/* <div className="col-12"> */}
          <div
            className="col-md-4 col-3"
            style={{
              zIndex: 999,
              //display: CommonUtil.isRootUser() ? "" : "none",
            }}
          >
            <span className="black-text css-16ihq8s">
              {CommonUtil.getMessageText(Constants.COMPANY, "Company")}
            </span>
            <SingleSelectComp
              dataSource={orgData}
              selectedValue={selCompanyObj}
              width={"100%"}
              handleChange={onCompanyChange}
              value={"orgId"}
              label={"orgName"}
              disabled={!CommonUtil.isRootUser() ? true : false}
            />
          </div>
          <div className="col-md-4 col-3 pt-4">
            <button
              type="button"
              className="btn btn-warning cus-btn btn-sm button-height"
              // onClick={filterReservations}
              onClick={getAllStatementsForOrg}
              // disabled={!CommonUtil.isRootUser() ? true : false}
            >
              {CommonUtil.getMessageText(Constants.SEARCH, "Search")}
            </button>

            <span
              style={{
                fontSize: "0.7rem",
                margin: "10px",
                textAlign: "right",
                position: "absolute",
                right: "5%",
              }}
            >
              Report data is generated for last cycle
            </span>
          </div>
        </div>

        <div className="row">
          <div className={`col-12 ${isMobile ? "" : "pt-3"}`}>
            {isMobile ? (
              <MGrid
                id="OrgConGrid"
                cols={eqpColumnDefs}
                rows={equipData}
                pageSize={10}
                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                ref={gridRef}
                addNew={false}
              />
            ) : (
              <div
                className="ag-theme-alpine gridHeight"
                style={{ height: "calc(100vh - 250px)", width: "100%" }}
              >
                <AGGrid
                  id="OrgConGrid"
                  cols={eqpColumnDefs}
                  rows={equipData}
                  pageSize={500}
                  headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                  rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                  ref={gridRef}
                  addNew={false}
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            fontSize: "1rem",
            textAlign: "right",
            margin: "20px",
          }}
        >
          {`Amount to be Paid: $ ${Number(totalAmount).toFixed(2)}`}
        </div>
      </div>
    </>
  );
};

export default Consolidatedinvoice;
