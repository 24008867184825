import React, { useEffect, useRef, useState } from "react";
import AGGrid from "../../Components/AGGrid";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import { IAGrid } from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import { CommonUtil } from "../../utils/CommonUtil";
import OrgUserFieldRenderer from "../Organization/OrgUserFieldRenderer";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import { Constants } from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import { Events } from "../Languages/Events";
import UserCheckBoxRender from "../Notification/userCheckBoxrender";
import { multiValueCSS } from "react-select/dist/declarations/src/components/MultiValue";
import { setAriaMultiSelectable } from "ag-grid-community/dist/lib/utils/aria";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import { Modal } from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import ReactAutocomplete from "react-autocomplete";

const OwnerServiceFree = () => {
  const feeGridRef = useRef<IAGrid>(null);
  const allOrgGridRef = useRef<IAGrid>(null);

  const [owner, setOwner] = useState("");
  const [renter, setRenter] = useState("");
  const [cad, setCAD] = useState("");
  const [percent, setPercent] = useState("");
  const [claims, setClaims] = useState("");
  const [exemptOrg, setExemptOrg] = useState<any>([]);
  const [nonexemptOrg, setNonExemptOrg] = useState([]);
  const [openpopup,setOpenpopup] = useState(false);

  const [serviceFeeDta, setServiceFeeDta] = useStateWithCallback<any>(
    [],
    () => {
      if (feeGridRef.current) {
        feeGridRef.current.columnFittoSize();
      }
    }
  );
  const [blockOrgColumns] = useState([
    {
      field: "equipmentType",
      headerName: CommonUtil.getMessageText(
        Constants.EQUIP_TYPE,
        "Equipment Type"
      ),
      resizable: true,
      flex: 1,
      width: 100,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "ownerServiceFee",
      headerName: CommonUtil.getMessageText(
        Constants.OWNER_SERVICE_FEE,
        "Owner Service Fee"
      ),
      resizable: true,
      flex: 1,
      width: 100,
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: OrgUserFieldRenderer,
    },
  ]);

  const [allOrgs,setAllOrgs] = useState<any>([]);
  const [columnDefs, setColumnDefs] = useState<any>([
    {
      field: "orgName",
      headerName: CommonUtil.getMessageText(
        Constants.ORGANIZATIONS,
        "Organizations",
      ),
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      // showDisabledCheckboxes: true,
      width: 200,
    },
    {
      field: "action",
      headerName:" ",
      width: 100,
      cellRenderer:(params:any)=>{
        const removefromList= (data:any)=>{
          let excludeDeleted:any = [];
          const orgObj = {...data};
          params.api.forEachNode(({data}:any) => {
            if(data.orgId != orgObj.orgId)
              excludeDeleted.push(data);
          });
          setAllOrgs(excludeDeleted);
          deleteExemptOrgList(orgObj); 
        }
        return (
          <div className={"cellRenderHeader"}>
            <a title="remove" onClick={()=>removefromList(params.data)}><i className="fa-solid fa-trash-can"></i></a>
        </div>
        )
      }
    },
  ]);

  const { isMobile } = DetectDevice();
  var priceArr: any[] = [
    { equipmentType: "Dry Van", ownerServiceFee: "20" },
    { equipmentType: "Plate Dry Van", ownerServiceFee: "30" },
    { equipmentType: "Storage Van", ownerServiceFee: "40" },
    { equipmentType: "Reefer", ownerServiceFee: "50" },
    { equipmentType: "Heated Van", ownerServiceFee: "60" },
    { equipmentType: "Flatbed", ownerServiceFee: "50" },
  ];

  useEffect(() => {
    setTimeout(() => {
      //setOrgs(priceArr);
      getOrgServiceFees();
      // if(feeGridRef.current)
      getExemptOrgs();
      
      document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    });
  }, []);

  let onSwitchCustomer = () => {
    if (CommonUtil.isRootUser()) {
      Events.dispatchBreadCrumbEvent();
      //navigate("orgList1");
    } else {
      getOrgServiceFees();
    }
  };

  let getOrgServiceFees = () => {
    setServiceFeeDta([]);
    let listAPI: string =
      "api/organization/getServiceFees?saasOrgId=" + CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      listAPI,
      "",
      "",
      "GET",
      true,
      onGetEquipmentTypePricing,
      onLoginError
    );
  };

  const getExemptOrgs = () => {
    setServiceFeeDta([]);
    let listAPI: string =
      "api/organization/getAllExemptOrganization?saasOrgId=" +
      CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      listAPI,
      "",
      "",
      "GET",
      true,
      onGetgetExemptOrgs,
      onLoginError
    );
  };

  const onGetgetExemptOrgs = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
        setExemptOrg(restData?.map((ele:any)=>({...ele,orgId:ele.exemptId})));
    }
    
    // getNonExemptOrg();
  };

  const getNonExemptOrg = () => {
    setServiceFeeDta([]);
    let listAPI: string =
      "api/organization/getNonExemptOrg?saasOrgId=" +
      CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      listAPI,
      "",
      "",
      "GET",
      true,
      onGetNonExemptOrg,
      onLoginError
    );
  };

  useEffect(()=>{    
    if(exemptOrg?.length > 0){
      setAllOrgs([...exemptOrg])
    }else{
      setAllOrgs([])
    }
      
  },[exemptOrg]);
  // useEffect(()=>{
  //   if(nonexemptOrg && exemptOrg){
  //     if(exemptOrg?.length > 0 && nonexemptOrg?.length > 0 ){
  //       setAllOrgs([...exemptOrg,...nonexemptOrg])
  //     }else if(exemptOrg?.length > 0){
  //       setAllOrgs([...exemptOrg])
  //     }else{
  //       setAllOrgs([...nonexemptOrg])
  //     }
  //   }

  //   setTimeout(()=>{
  //     if(allOrgGridRef?.current && exemptOrg?.length > 0){    
  //       allOrgGridRef?.current?.getGridAPI().forEachNode((node:any) => {
  //         const existsArr:any = exemptOrg?.filter((ele:any)=>ele.orgId===node?.data?.orgId);
  //         // console.log(node?.data?.saasOrgId,existsArr);
  //         if (existsArr.length > 0) {
  //           node.setSelected(true) ;
  //         }      
  //       });    
  //     }
  //   })  

  // },[nonexemptOrg,exemptOrg]);

  const onGetNonExemptOrg = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      setNonExemptOrg(restData?.map((ele:any)=>({...ele,orgId:ele.saasOrgId})));
    }
  };

  let onGetEquipmentTypePricing = (dataObj: any) => {
    //console.log(dataObj);
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        var orgs = [];
        setOwner(restData.ownerRentalPercentage);
        setRenter(restData.renterRentalPercentage);
        setCAD(restData.ownerRepositioningMinimumCost);
        setPercent(restData.ownerRepositioningPercentage);
        setClaims(restData.claimFeePercentage);

        if (restData.equipmentTypeServiceFees) {
          if (Array.isArray(restData.equipmentTypeServiceFees)) {
            orgs = restData.equipmentTypeServiceFees;
          } else {
            orgs.push(restData.equipmentTypeServiceFees);
          }
        }

        setServiceFeeDta(orgs);
      }
    }

    setTimeout(() => {
      if (feeGridRef.current) {
        // feeGridRef.current.columnFittoSize();
      }
    }, 2000);
  };

  let saveDailyPrices = () => {
    var prices: any[] = [];
    var gridAPI: any = feeGridRef.current?.getGridAPI();
    if (gridAPI) {
      //console.log(priceGridRef.current?.getGridAPI());
      var rows: any[] = gridAPI.rowModel.rowsToDisplay;
      rows.forEach((item, idx) => {
        //console.log(item.data);
        prices.push(item.data);
      });
    }

    var reqObj: any = {};
    reqObj["claimFeePercentage"] = claims;
    reqObj["ownerRentalPercentage"] = owner;
    reqObj["renterRentalPercentage"] = renter;
    reqObj["ownerRepositioningMinimumCost"] = cad;
    reqObj["ownerRepositioningPercentage"] = percent;
    reqObj.equipmentTypeServiceFees = prices;

    var listAPI: string =
      "api/organization/saveServiceFees?saasOrgId=" +
      CommonUtil.getSelectedOrgId();
    NetworkManager.sendJsonRequest(
      listAPI,
      reqObj,
      "",
      "POST",
      true,
      onSaveServicePricing,
      onLoginError
    );
  };

  const saveExemptOrgList = () =>{
    if(allOrgGridRef.current){
      if(allOrgGridRef.current.getSelectedRows()){
        const reqObj = allOrgGridRef.current.getSelectedRows()?.map(({orgId}:any)=>orgId);
        // const deleteditems = reqObj?.filter((ele:number)=>{
        //   return exemptOrg?.findIndex((item:any)=>item.orgId===ele) === -1
        // });
        const deleteditems:any = exemptOrg?.filter((item:any)=>!reqObj.includes(item.orgId))?.map(({orgId}:any)=>orgId);
        const additems:any = reqObj?.filter((ele:number)=>{
          return exemptOrg?.findIndex((item:any)=>item.orgId===ele) === -1
        })
console.log(additems,deleteditems);

        let listAPI: string =
        "api/organization/addExemptOrganization?saasOrgId=" +
        CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(
          listAPI,
          additems,
          "",
          "POST",
          true,
          onAddExemptOrganization,
          onLoginError
        );

        let deleteAPI: string =
        "api/organization/removeExemptRenter?exemptId=" +
        deleteditems?.join(',')
        NetworkManager.sendJsonRequest(
          listAPI,
          additems,
          "",
          "POST",
          true,
          onDeleteExemptOrganization,
          onLoginError
        );
      }
    }
  }

  const addExemptOrganization = (data:any) =>{
    
        const reqObj = [data.orgId]
        let listAPI: string =
        "api/organization/addExemptOrganization?saasOrgId=" +
        CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(
          listAPI,
          reqObj,
          "",
          "POST",
          true,
          onAddExemptOrganization,
          onLoginError
        );
      
  }
  const deleteExemptOrgList = (data:any) =>{
    
        let deleteAPI: string =`api/organization/removeExemptRenter?saasOrgId=${CommonUtil.getSelectedOrgId()}&exemptId=${data.orgId}`;        
        NetworkManager.sendJsonRequest(
          deleteAPI,
          "",
          "",
          "DELETE",
          true,
          onDeleteExemptOrganization,
          onLoginError
        );
     
  }

const onAddExemptOrganization = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      let strUpdated = CommonUtil.getMessageText(
          Constants.UPDATED_SUCCESSFULLY,
          "Added Successfully"
        );
        ToastManager.showToast(strUpdated, "Info");
    }
  }

  const onDeleteExemptOrganization = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      let strUpdated = CommonUtil.getMessageText(
          Constants.UPDATED_SUCCESSFULLY,
          "Removed Successfully"
        );
        ToastManager.showToast(strUpdated, "Info");
    }
  }

  const onRowSelected = (params:any)=>{
    // console.log(params.data.orgId,params);    
  }

  
  let onSaveServicePricing = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        let strUpdated = CommonUtil.getMessageText(
          Constants.UPDATED_SUCCESSFULLY,
          "Updated Successfully"
        );
        ToastManager.showToast(strUpdated, "Info");
      }
    }
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  return (
    <>   
    <style>{`
        .ag-header-select-all{
          padding-left:0px;
        }
      `}</style>
      <div
        className="container-fluid container-fluid-padding"
        style={{ background: "#f9f9f9" }}
      >
        <br />
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3  mb-3 h-100">
            <table
              className="owner-service-fees-table w-100"
              style={{ background: "#FFF" }}
            >
              <tr>
                <th
                  colSpan={2}
                  className="pl-4 pt-3"
                  style={{ paddingLeft: "1.5rem" }}
                >
                  {CommonUtil.getMessageText(
                    Constants.RENTAL_SERVICE_FEE,
                    "Rental Service Fee"
                  )}
                </th>
              </tr>
              <tr>
                <td className="text-center pl-4 pr-4 pb-4">
                  <p className="m-0">
                    {CommonUtil.getMessageText(
                      Constants.FOR_RENTER,
                      "For Renter"
                    )}
                  </p>
                  <input
                    type="form-control"
                    className="service-input"
                    value={renter}
                    disabled={CommonUtil.isRootUser() ? false : true}
                    onChange={(e) => {
                      setRenter(e.currentTarget.value);
                    }}
                  />
                  <span style={{ fontWeight: "bold" }}> %</span>
                </td>
                <td className={`text-center pl-4 pr-4 pb-4 `}>
                  <p className="m-0">
                    {CommonUtil.getMessageText(
                      Constants.FOR_OWNER,
                      "For Owner"
                    )}
                  </p>
                  <input
                    type="form-control"
                    className="service-input"
                    value={owner}
                    disabled={CommonUtil.isRootUser() ? false : true}
                    onChange={(e) => {
                      setOwner(e.currentTarget.value);
                    }}
                  />{" "}
                  <span style={{ fontWeight: "bold" }}> %</span>
                </td>
              </tr>
            </table>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6  mb-3 h-100">
            <table
              className="owner-service-fees-table w-100"
              style={{ background: "#FFF" }}
            >
              <thead>
                <th
                  colSpan={3}
                  className="pl-4 pt-3"
                  style={{ paddingLeft: "1.5rem" }}
                >
                  {CommonUtil.getMessageText(
                    Constants.REPOSITION_SERVICE,
                    "Repositioning Service"
                  )}
                </th>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-4 pb-4" style={{ paddingLeft: "1.5rem" }}>
                    <p className="m-0" />
                    {CommonUtil.getMessageText(
                      Constants.VHUB_SERVICE_FEE,
                      "The vHub Service fee is equal to the highest of"
                    )}
                    <br />
                    &nbsp;{" "}
                    {CommonUtil.getMessageText(
                      Constants.MIN_COST_AMOUNT,
                      "(i) the minimum cost amount and"
                    )}
                    <br />
                    &nbsp;{" "}
                    {CommonUtil.getMessageText(
                      Constants.PERCENT_SERVICE_AMOUNT,
                      "(ii) the percentage of the positioning service amount"
                    )}{" "}
                  </td>
                  <td className="text-center pb-4">
                    <p style={{ marginBottom: "0px" }}>CAD</p>
                    <input
                      type="form-control"
                      className="service-input"
                      value={cad}
                      disabled={CommonUtil.isRootUser() ? false : true}
                      onChange={(e) => {
                        setCAD(e.currentTarget.value);
                      }}
                      style={{ width: "60%" }}
                    />
                  </td>
                  <td className="text-center pr-4 pb-4">
                    <p style={{ marginBottom: "0px" }}>
                      {CommonUtil.getMessageText(Constants.PERCENT, "Percent")}
                    </p>
                    <input
                      type="form-control"
                      className="service-input"
                      value={percent}
                      disabled={CommonUtil.isRootUser() ? false : true}
                      onChange={(e) => {
                        setPercent(e.currentTarget.value);
                      }}
                      style={{ width: "60%" }}
                    />{" "}
                    <span style={{ fontWeight: "bold" }}> %</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm-3 col-md-3 col-lg-3 mb-3 h-100">
            <table
              className="owner-service-fees-table w-100"
              style={{ background: "#FFF" }}
            >
              <thead>
                <tr>
                  <th className="pl-4 pt-3" style={{ paddingLeft: "1.5rem" }}>
                    {CommonUtil.getMessageText(
                      Constants.CLAIM_SERVICE_FEE,
                      "Claim Service Fee (%)"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center pl-4 pr-4 pb-4">
                    <p style={{ marginBottom: "0px" }}>
                      {CommonUtil.getMessageText(Constants.CLAIMS, "Claims")}
                    </p>
                    <input
                      type="form-control"
                      className="service-input"
                      value={claims}
                      disabled={CommonUtil.isRootUser() ? false : true}
                      onChange={(e) => {
                        setClaims(e.currentTarget.value);
                      }}
                    />{" "}
                    <span style={{ fontWeight: "bold" }}> %</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/*<div className="row" style={{display:"none"}}>
                    <div className="col-md-3 text-center">
                        <div className="row" style={{border:"1px solid #0dcaf0",width:"103%",height:"100%"}}>
                            <div className="col-md-12 renter-service" style={{height:"40px",borderBottom:"1px solid #0dcaf0"}}>
                                <span className="clsCustProfDefPricingTxt clsBoldText fw-600">Rental Service Fee (%)</span>
                            </div>
                            <div className="col-md-12" style={{display:"flex",marginTop:"-50px"}}>
                                <div className="col-md-6 service-box" style={{borderRight:"1px solid #0dcaf0"}}>
                                    <span className="clsCustProfDefPricingTxt">For Renter </span>
                                    <span className="clsCustProfDefPricingTxt fw-600 ptop-5">
                                        <input type="form-control" className="service-input" value={renter} disabled={CommonUtil.isRootUser()?false:true}
                                               onChange={(e)=>{setRenter(e.currentTarget.value)}} />
                                    </span>
                                </div>
                                <div className="col-md-6 service-box">
                                    <span className="clsCustProfDefPricingTxt ptop-5">For Owner </span>
                                    <input type="form-control" className="service-input" value={owner} disabled={CommonUtil.isRootUser()?false:true}
                                           onChange={(e)=>{setOwner(e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 text-center">
                        <div className="row" style={{border:"1px solid #0dcaf0",width:"101%",height:"100%"}}>
                            <div className="col-md-12 service-box-height renter-service" style={{borderBottom:"1px solid #0dcaf0"}}>
                                <span className="clsCustProfDefPricingTxt clsBoldText fw-600">Repositioning Service</span>
                            </div>
                            <div className="col-md-12" style={{paddingLeft:"2px",display:"flex",height:"100px"}}>
                                <div className="col-md-6 service-box" style={{borderRight:"1px solid #0dcaf0",paddingRight:"5px",paddingLeft:"5px"}}>
                                    <span className="clsCustProfDefPricingTxt" style={{textAlign:"justify"}}>The vHub Service fee is equal to the highest of (i) the minimum cost amount and (ii) the percentage of the positioning service amount </span>
                                </div>
                                <div className="col-md-3 service-box" style={{borderRight:"1px solid #0dcaf0"}}>
                                    <span className="clsCustProfDefPricingTxt">CAD </span>
                                    <input type="form-control" className="service-input" value={cad} style={{paddingLeft:"10px"}}
                                           disabled={CommonUtil.isRootUser()?false:true} onChange={(e)=>{setCAD(e.currentTarget.value)}}/>
                                </div>
                                <div className="col-md-3 service-box">
                                    <span className="clsCustProfDefPricingTxt">Percent </span>
                                    <input type="form-control" className="service-input" value={percent} style={{paddingLeft:"10px"}}
                                           disabled={CommonUtil.isRootUser()?false:true} onChange={(e)=>{setPercent(e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center">
                        <div className="row" style={{border:"1px solid #0dcaf0",height:"100%"}}>
                            <div className="col-md-12  renter-service" style={{height:"40px",borderBottom:"1px solid #0dcaf0"}}>
                                <span className="clsCustProfDefPricingTxt clsBoldText fw-600">Claim Service Fee (%)</span>
                            </div>
                            <div className="col-md-12" style={{display:"flex",marginTop:"-50px"}}>
                                <div className="col-md-12 service-box">
                                    <span className="clsCustProfDefPricingTxt">Claims </span>
                                    <input type="form-control" className="service-input" value={claims} style={{paddingLeft:"30px"}}
                                           disabled={CommonUtil.isRootUser()?false:true} onChange={(e)=>{setClaims(e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
        <div className="row fs-7 pt-4">
          <div
            className="col-md-12"
            style={{ paddingLeft: "0px", paddingBottom: "5px" }}
          >
            <label style={{ display: "none" }}>Rental Service Fee(%) </label>
          </div>
          {isMobile ? (
            <MGrid
              id="CustOwnerServiceFeeGrid"
              cols={blockOrgColumns}
              rows={serviceFeeDta}
              pageSize={10}
              headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
              rowHeight={CommonUtil.GRID_ROW_HEIGHT}
              ref={feeGridRef}
            />
          ) : (
            <>
              <table
                className="owner-service-fees-table w-100"
                style={{ background: "#FFF" }}
              >
                <tr>
                  <th className="p-2 text-left">Owner Service based on Equipment type</th>
                  <th
                    className="p-2 text-left"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    <div className="row"><div className="col-6">Exempt Customers</div>
                      <div className="col-6 mr-1 text-end">
                        <div className="btn-group btn-group-justified" >
                          <a className="btn btn-default login-btn" title="Add Exempted Orgs"
                           style={{background: "rgb(239, 195, 0)", display:"flex; align-items: center"}} onClick={()=>{
                            setOpenpopup(true);
                           }}>
                            <i className="fas fa-plus fa-sm "></i>
                            <span style={{marginLeft: "0px"}}></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td className="col-8 m-0 p-0">
                    <div
                      className="ag-theme-alpine gridHeight"
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        height: "calc(100vh - 420px)",
                        width: "100%",
                      }}
                    >
                      <AGGrid
                        id="CustOwnerServiceFeeGrid"
                        cols={blockOrgColumns}
                        rows={serviceFeeDta}
                        pageSize={500}
                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                        rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                        ref={feeGridRef}
                        hideToolbar={true}
                      />
                    </div>
                  </td>
                  <td className="col m-0 p-0">
                    <div
                      className="ag-theme-alpine gridHeight"
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        height: "calc(100vh - 420px)",
                        width: "100%",
                      }}
                    >
                      <AGGrid
                        cols={columnDefs}
                        rows={allOrgs}
                        pageSize={500}
                        headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                        rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                        hideToolbar={true}
                        rowSelection={"single"}
                        // rowMultiSelectWithClick={true}
                        ref={allOrgGridRef}
                        onRowSelected={onRowSelected}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="text-right p-2">
                    <div className="row">
                      <div className="">
                        <div className="float-end">
                          <button
                            style={{ display: "none" }}
                            className="btn btn-outline-dark"
                          >
                            Discard Changes
                          </button>
                          &nbsp;&nbsp;
                          {CommonUtil.getSelectedOrgName() == "vHub Inc" ? (
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={saveDailyPrices}
                            >
                              {CommonUtil.getMessageText(
                                Constants.SAVE,
                                "Save"
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th
                    className="text-right p-2"
                    style={{ borderLeft: "1px solid lightgray" }}
                  >
                    <div className="row">
                      <div className="">
                        <div className="float-end">
                          <button
                            style={{ display: "none" }}
                            className="btn btn-outline-dark"
                          >
                            Discard Changes
                          </button>
                          &nbsp;&nbsp;
                          {/* {CommonUtil.getSelectedOrgName() == "vHub Inc" ? (
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={saveExemptOrgList}
                            >
                              {CommonUtil.getMessageText(
                                Constants.SAVE,
                                "Save"
                              )}
                            </button>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </table>
            </>
          )}
        </div>
        {isMobile && (
          <div className="row">
            <div className="">
              <div className="my-3 float-end">
                <button
                  style={{ display: "none" }}
                  className="btn btn-outline-dark"
                >
                  Discard Changes
                </button>
                &nbsp;&nbsp;
                {CommonUtil.getSelectedOrgName() == "vHub Inc" ? (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={saveDailyPrices}
                  >
                    {CommonUtil.getMessageText(Constants.SAVE, "Save")}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <OrgFetch onSave={(org:any)=>{
        console.log("save ",org);   
        let exemptedList:any = exemptOrg?.length ? [...exemptOrg] : [];
        if(allOrgs?.length >0){
          exemptedList = [...allOrgs,org]
        }else{
          exemptedList = [...exemptedList,org]
        }
        
        setAllOrgs(exemptedList);
        addExemptOrganization(org);
        // setAllOrgs([...exemptOrg,...nonexemptOrg])
        setOpenpopup(false)     
      }} showBlockOrgPopup={openpopup} exemptedList={allOrgs}  setShowBlockOrgPopup={(flag:boolean)=>setOpenpopup(flag)}/>
    </>
  );
};

const OrgFetch = ({onSave,exemptedList,showBlockOrgPopup,setShowBlockOrgPopup}:any)=>{
  const [blockOrgs, setBlockOrgs] = useState<any[]>([]);
  // const [showBlockOrgPopup, setShowBlockOrgPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [blockOrg, setBlockOrg] = useState<any>(null);

  useEffect(() => {
    console.log(exemptedList);
    
    if(showBlockOrgPopup)
      getAllOrgs();
  },[showBlockOrgPopup]);

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let getAllOrgs = () => {
    NetworkManager.sendJsonRequest(
      "api/organization/listNames",
      "",
      "",
      "GET",
      true,
      onGetListNames,
      onLoginError
    );
  };

  let onGetListNames = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData) {
        if (restData.statusCode) {
          //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
          ToastManager.showToast(
            StatusCodeUtil.getCodeMessage(restData.statusCode),
            "Error"
          );
        } else {
          var orgs = [];
          if (Array.isArray(restData.organizations)) {
            orgs = restData.organizations;
          } else {
            orgs.push(restData.organizations);
          }
          //setBlockOrgs(orgs);
          var listOrgs: any = [];
          orgs.forEach((item: any, idx: number) => {
            if (item.orgId == CommonUtil.getSaasOrgId()) {
            } else {
              if (!isOrgIsBlocked(item.orgId)) {
                listOrgs.push(item);
              }
            }
          });
          setBlockOrgs(listOrgs);
        }
      }
    }
  };

  let isOrgIsBlocked = (orgId: string) => {
    for (let i = 0; i < exemptedList?.length; i++) {
      let orgItem: any = exemptedList[i];
      if (orgItem && orgItem.orgId == orgId) {
        return true;
      }
    }
    return false;
  };

  const menustyle: any = {
    top: 27,
    left: 0,
    position: "absolute",
    zIndex: 999,
    background: "#f5f5f5",
    height: "auto",
    maxHeight: "215px",
    overflowY: "scroll",
    borderRadius: 8,
    padding: 5,
    width: "100%",
    boxShadow: "35px 35px 70px #e4e4e4 -35px -35px 70px #ffffff",
  };
  const onChangeText = (e: any) => {
    if (e.target.value.length > 0) {
      setOpen(true);
    } else if (e.target.value.length === 0) {
      setOpen(false);
    }
    setValue(e.target.value);
  };
  const onSelect = (value: any, item: any) => {
    setOpen(false);
    setValue(value);
    setBlockOrg(item);
  };
 
  const renderItem = (item: any, highlighted: any) => (
    <div
      key={item.orgId}
      style={{ backgroundColor: highlighted ? "lightblue" : "transparent" }}
    >
      {" "}
      {item.orgName}
    </div>
  );

  return (
    <Modal show={showBlockOrgPopup} dialogAs={DraggableComponent}>
        <div
          className="modal-content"
          // style={{ width: "550px", height: "500px" }}
        >
          <div className="modal-header">
            <h6 className="modal-title">
              {CommonUtil.getMessageText(
                Constants.BLOCK_ORGANIZATION,
                "Block Ogranization"
              )}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={()=>setShowBlockOrgPopup(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row fs-7">
              <div className="col-md-12">
                <label>
                  {CommonUtil.getMessageText(
                    Constants.ORG_NAME,
                    "Organization"
                  )}
                </label>
                <span className="clsFormMandatory"></span>
                <div className="input-group mb-3">
                  {/* <SingleSelectComp  dataSource={blockOrgs} selectedValue={blockOrg}
                                                      handleChange={onOrgChange} value={"orgId"} label={"orgName"} className={blockOrgClass} /> */}
                  <ReactAutocomplete
                    open={open}
                    items={blockOrgs}
                    shouldItemRender={(item: any, value: any) => {
                      return (
                        item.orgName
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) > -1
                      );
                    }}
                    getItemValue={(item: any) => item.orgName}
                    wrapperStyle={{
                      position: "relative",
                      width: "100%",
                    }}
                    inputProps={{
                      className: `mandatory custom-select rounded`,
                      style: {
                        width: "100%",
                        height: "35px",
                      },
                    }}
                    menuStyle={menustyle}
                    renderMenu={(items, value) => (
                      <div style={menustyle}>
                        {items.length === 0 ? `No matches for ${value}` : items}
                      </div>
                    )}
                    renderItem={renderItem}
                    value={value}
                    onChange={onChangeText}
                    onSelect={(value, item) => {
                      onSelect(value, item);
                    }}
                  />
                </div>
              </div>
            </div>
           
            </div>
          </div>
          <div className="modal-footer clsModalPermissionsFooter">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-bs-dismiss="modal"
              onClick={()=>setShowBlockOrgPopup(false)}
            >
              {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-success"
              onClick={()=>onSave(blockOrg)}
            >
              {CommonUtil.getMessageText(Constants.SAVE, "Save")}
            </button>
          </div>
        
      </Modal>
  )
}

export default OwnerServiceFree;
