import { EnglishLan } from "./EnglishLan";
import { CommonUtil } from "../../utils/CommonUtil";
import { FrenchLan } from "./FrenchLan";
import { SpanishLan } from "./SpanishLan";

export class Constants {
    static EQUIPMENT_REPORT = "Equipment Report";
    static EQUIPMENT_SCREEN = "Equipments";
    static DRIVER_SCREEN = "Driver";
    static PARKING_LOT_SCREEN = "Parking Lot";
    static MASTER_DATA_SCREEN = "Master data import";
    static INSPECTION_COMPANY_SCREEN = "Inspection Company";
    static CUSTOMER_PROFILES_SCREEN = "Customer Profiles";
    static ORG_SCREEN = "Organizations";
    static SEARCH_DASHBOARD = "Search Dashboard";
    static NEW_SEARCH_DASHBOARD = "New Search Dashboard";
    static FAVORITIES_SCREENS = "Favorities";
    static AVAILABILITY_SCREENS = "Availabilities";
    static OWNER_RESERVATION_SCREENS = "Owner Reservation";
    static ADMIN_RESERVATION_SCREENS = "Reservation";
    static RENTER_RESERVATION_SCREENS = "Renter Reservation";
    static OFFER_SENT_SCREENS = "Offers Sent";
    static OFFER_RECEIVED_SCREENS = "Offers received";
    static API_HUB_SCREEN = "API HUB";
    static NOTIFICATION_SCREEN = "Notifications";
    static INSURANCE_PLANS_SCREEN = "Insurance Plans";
    static RATE_TABLE_SCREEN = "Rate Table";
    static CUSTOMER_CONTRACT_DETAILS = "CUSTOMER_CONTRACT_DETAILS"
    static INTERCHANGE_NOTIFICATIONS = "INTERCHANGE_NOTIFICATIONS"
    static ENABLE_TO_RECEIVE_NOTIFICATION = "ENABLE_TO_RECEIVE_NOTIFICATION"

    static EQUIPMENT_REPORT1 = "EQUIPMENT_REPORT";//Equipment Report
    static EQUIPMENT_SCREEN1 = "EQUIPMENT_SCREEN";//Equipments
    static DRIVER_SCREEN1 = "DRIVER_SCREEN";//Driver
    static PARKING_LOT_SCREEN1 = "PARKING_LOT_SCREEN";//Parking Lot
    static MASTER_DATA_SCREEN1 = "MASTER_DATA_SCREEN";//Master data import
    static CUSTOMER_PROFILES_SCREEN1 = "CUSTOMER_PROFILES_SCREEN";//Customer Profiles
    static ORG_SCREEN1 = "ORG_SCREEN";//Organizations
    static SEARCH_DASHBOARD1 = "SEARCH_DASHBOARD";//Search Dashboard
    static FAVORITIES_SCREENS1 = "FAVORITIES_SCREENS";//Favorities
    static AVAILABILITY_SCREENS1 = "AVAILABILITY_SCREENS";//Availabilities
    static OWNER_RESERVATION_SCREENS1 = "OWNER_RESERVATION_SCREENS";//Owner Reservation
    static ADMIN_RESERVATION_SCREENS1 = "ADMIN_RESERVATION_SCREENS";//Admin Reservation
    static RENTER_RESERVATION_SCREENS1 = "RENTER_RESERVATION_SCREENS";//Renter Reservation
    static OFFER_SENT_SCREENS1 = "OFFER_SENT_SCREENS";//Offers Sent
    static OFFER_RECEIVED_SCREENS1 = "OFFER_RECEIVED_SCREENS";//Offers received
    static API_HUB_SCREEN1 = "API_HUB_SCREEN";//API HUB
    static NOTIFICATION_SCREEN1 = "NOTIFICATION_SCREEN";//Notifications
    static INSURANCE_PLANS_SCREEN1 = "INSURANCE_PLANS_SCREEN";//Insurance Plans
    static RATE_TABLE_SCREEN1 = "RATE_TABLE_SCREEN";//Rate Table
    static INSPECTION_COMPANY1 = "INSPECTION_COMPANY_SCREEN";
    static INTERCHANGE_APPLICATION1 = "INTERCHANGE_APPLICATION";
    static TRAILER_NUMBER = "TRAILER_NUMBER";
    static EQUIPMENT_ID = "EQUIPMENT_ID"
    static COMMENTS = "COMMENTS"
    static ERRORS = "ERRORS"
    static ENTER_BROKER_NAME = "ENTER_BROKER_NAME"
    static ROW_NUMBER = "ROW_NUMBER"
    static ADD_COMMENTS = "ADD_COMMENTS"

    static ADMINISTRATION = "ADMINISTRATION"; // Administration
    static EQUIPMENTS = "EQUIPMENTS"; // Equipments
    static EQUIP_REPORT = "EQUIPMENT REPORT"; // Equipments
    static DASHBOARD = "DASHBOARD"; // Dashboard
    static REPORTS = "REPORTS"; // Reports
    static SEARCH_DB = "SEARCH_DB"; // Search Dashboard
    static RESERVATION_DETAILS = "RESERVATION_DETAILS"; // Reservation Details
    static AVAILABILITIES = "AVAILABILITIES"; // Availabilities
    static ORGANIZATIONS = "ORGANIZATIONS"; // Organizations
    static OWNER_RESERVATIONS = "OWNER_RESERVATIONS"; //Owner Reservations
    static ADMIN_RESERVATIONS = "ADMIN_RESERVATIONS"; //Admin Reservations
    static RENTER_RESERVATIONS = "RENTER_RESERVATIONS"; //Renter Reservations
    static OFFERS_SENT = "OFFERS_SENT"; //Offers Sent
    static OFFERS_RECEIVED = "OFFERS_RECEIVED"; //Offers Received
    static EQUIPMENT_VIEW = "EQUIPMENT_VIEW"; //Equipment View
    static CUSTOMER_PROFILES = "CUSTOMER_PROFILES";//Customer Profiles
    static DRIVER = "DRIVER";//Driver
    static DRIVERS = "DRIVERS";//Drivers
    static PARKING_LOT = "PARKING_LOT";//Parking Lot
    static NOTIFICATIONS = "NOTIFICATIONS";//Notifications
    static VIEW_RESERVATION = "VIEW_RESERVATION";//View Reservation
    static EQUIP_PUBLIC_VIEW = "EQUIP_PUBLIC_VIEW";//Equipment Public View
    static SUCCESS_PASS_UPDATE = "SUCCESS_PASS_UPDATE";//Password updated successfully
    static INFO = "INFO";//Info
    static SUCCESS = "SUCCESS";//Success
    static NOTIFICATION_CONFIG = "NOTIFICATION_CONFIG";//Notification configured successfully
    static MY_PROFILE_UPDATE = "MY_PROFILE_UPDATE";//My Profile Updated Successfully
    static COMPANY_DB = "COMPANY_DB";//Company Dashboard
    static SWITCH_ORG = "SWITCH_ORG";//Switch Ogranization
    static HOME_ORG = "HOME_ORG";//Return to vHub Organization
    static SWITCH = "SWITCH";//Switch
    static INVOICES = "INVOICES";//Invoices
    static MONTHLY_INVOICES = "MONTHLY_INVOICES";//MONTHLY_INVOICES
    static SOI = "SOI";//SOI
    static FAVORITIES = "FAVORITIES";//favourites
    static PROCESS_RESERVATIONS = "PROCESS_RESERVATIONS"; //Process Reservations
    static API_HUB = "API_HUB" //API HUB
    static RESERVATIONS_MADE = "RESERVATIONS_MADE";//Reservations made
    static OFFERS = "OFFERS";//Offers
    static SWITCH_CUST = "SWITCH_CUST";//Switch Customer
    static LOGOUT = "LOGOUT";//Logout
    static VIEW_HISTORY = "VIEW_HISTORY";//View History
    static SEARCH_DB_PUBLIC = "SEARCH_DB_PUBLIC";
    static SEARCH_DB_PRIVATE = "SEARCH_DB_PRIVATE";
    static SEARCH_DB1 = "SEARCH_DB1";
    static PROCESS = "PROCESS";//Process
    static FIELD = "FIELD";//Field
    static FROM_VALUE = "FROM_VALUE";//From Value
    static TO_VALUE = "TO_VALUE";//To Value
    static PERFOMED_BY = "PERFOMED_BY";//Performed By
    static PERFOMED_DATE = "PERFOMED_DATE";//Performed Date
    static LOGO_UPDATED = "LOGO_UPDATED";//Logo updated successfully
    static ORG_UPDATED = "ORG_UPDATED";//Organization updated successfully
    static OWNER_APPROVE_SUCCESS = "OWNER_APPROVE_SUCCESS";//"Owner Approved successfully
    static OWNER_DIS_APPROVE_SUCCESS = "OWNER_DIS_APPROVE_SUCCESS";//Owner DisApproved successfully
    static FILE_UPDATED_DATE = "FILE_UPDATED_DATE";
    static DOWNLOAD = "DOWNLOAD"

    static OWNER_ACC_STATUS = "OWNER_ACC_STATUS";//Owner Account Status is
    static OWNER_ACC_APPROVED = "OWNER_ACC_APPROVED";//Owner Account Approved!
    static RENTER_ACC_STATUS = "RENTER_ACC_STATUS";//Renter Account Status is
    static RENTER_ACC_APPROVED = "RENTER_ACC_APPROVED";//Renter Account Approved!
    static DISCARD_CHANGES = "DISCARD_CHANGES";//Discard Changes

    static FINANCE_INFO_UPDATE = "FINANCE_INFO_UPDATE";//"Financial Information updated Successfully";
    static FINANCE_INSURENCE_UPDATE = "FINANCE_INSURENCE_UPDATE";//Financial Insurance updated Successfully
    static FINANCE_TAX_UPDATE = "FINANCE_TAX_UPDATE";//Financial Tax Exemption updated Successfully
    static BANK_UPDATE_SUCCESS = "BANK_UPDATE_SUCCESS";//Bank Info updated Successfully
    static CARD_UPDATE_SUCCESS = "CARD_UPDATE_SUCCESS";//Credit Card Info updated Successfully
    static CREDITCARD_NOTE = "CREDITCARD_NOTE";//Note: Only Visa and Mastercard credit cards are accepted
    static DRAG_DRAP_FILES = "DRAG_DRAP_FILES";//Drag and Drop files here Or
    static FINANCE_INFO = "FINANCE_INFO";//Finance Information
    static ENTER_GST_NUMBER = "ENTER_GST_NUMBER";//You must enter a GST/HST Number
    static BUSINESS_NUMBER_EIN = "BUSINESS_NUMBER_EIN";//Business Number (EIN in Us)
    static HEADQUARTER_QUEBEC = "HEADQUARTER_QUEBEC";//(For companies with their headquarters in Quebec)
    static HEADQUARTER_QUEBEC_OUTSIDE = "HEADQUARTER_QUEBEC_OUTSIDE";//(For companies with their headquarters outside of Quebec)
    static ENTER_QST_PST_NUMBER = "ENTER_QST_PST_NUMBER";//You must enter a QST/PST Number
    static DOWNLOAD_W8_FORM = "DOWNLOAD_W8_FORM";//"Download W8 Form
    static DOWNLOAD_W9_FORM = "DOWNLOAD_W9_FORM";//Download W9 Form
    static TAX_EXEMPTION = "TAX_EXEMPTION";//Tax Exemption
    static EXEMPTION_REASON = "EXEMPTION_REASON";//Exemption Reason
    static SIGNATURE_DATE = "SIGNATURE_DATE";//Signature Date
    static SWITF_NOTE = "SWITF_NOTE";//Note: By using SWIFT, additional processing fees may be applied on the side of your financial institution
    static INTERNET_BANK_DETAILS = "INTERNET_BANK_DETAILS";//Note: You can usually find these details by logging into your internet bank. From there you should easily be able to see the account number of the account you would like to use, the institution number of the bank, and the transit number of the branch
    static DOWNLOAD_FORM = "DOWNLOAD_FORM";//Download Form
    static BANK_DOWNLOAD_FORM = "BANK_DOWNLOAD_FORM";//Download Auto Debit Authorization Form
    static CARD_NUMBER = "CARD_NUMBER";//Card Number
    static EXPIRY_DATE = "EXPIRY_DATE";//Expiry Date

    static USER_STATUS_UPDATED = "USER_STATUS_UPDATED";//User Status Updated Successfully
    static CONFIRM = "CONFIRM";//Confirm
    static RESEND_INVITATION = "RESEND_INVITATION";//Do you want Resend Invitation?
    static RESEND_SUCCESS = "RESEND_SUCCESS";//Resend Invitation Successfully
    static USER_CREATED_SUCCESS = "USER_CREATED_SUCCESS";//User Created Successfully
    static USER_MADE_PRIMARY = "USER_MADE_PRIMARY";//User Created Successfully
    static PREVENT_USER_APP = "PREVENT_USER_APP";//This action will prevent the user from using the vHub Application
    static SHOW_USER_APP = "SHOW_USER_APP";//This action will allow the user to access the vHub Application
    static INACTIVE_USER = "INACTIVE_USER";//Inactivate User
    static ACTIVE_USER = "ACTIVE_USER";//Activate User
    static BLOCK_USER = "BLOCK_USER";//Block User
    static INVITE_USER = "INVITE_USER";//Invite User


    static NUM_OF_DAY_ALLOWED = "NUM_OF_DAY_ALLOWED";//Number of days allowed for repositioning is less than or equal to between days
    static ENTER_NUM_OF_DAYS = "ENTER_NUM_OF_DAYS";
    static EMAIL = "EMAIL";
    static PASS = "PASS";
    static F_PASS = "F_PASS";
    static SIGN_UP = "SIGN_UP";
    static DONT_ACCOUNT = "DONT_ACCOUNT";
    static LOGIN = "LOGIN";
    static SEARCH = "SEARCH";
    static WELCOME = "WELCOME";
    static CREDENTIALS = "CREDENTIALS";
    static FORGET_PASS = "FORGET_PASS";
    static ENTER_EMAIL = "ENTER_EMAIL";
    static PLACE_ENTER_EMAIL = "PLACE_ENTER_EMAIL";
    static CANCEL = "CANCEL";
    static EMAIL_ID = "EMAIL_ID";
    static RESET_PASSWORD = "RESET_PASSWORD";
    static CLOSE = "CLOSE";
    static SAVE = "SAVE";
    static AUTHORIZE = "AUTHORIZE";
    static CHANGE_PASSWORD = "CHANGE_PASSWORD";
    static OLD_PASSWORD = "OLD_PASSWORD";
    static NEW_PASSWORD = "NEW_PASSWORD";
    static CONFIRM_NEW_PASSWORD = "CONFIRM_NEW_PASSWORD";
    static ORGANISATION_INFO = "ORGANISATION_INFO";
    static FINANCE_INSURANCE = "FINANCE_INSURANCE";
    static CONTACTS = "CONTACTS";
    static OWNER_SERVICE_FEES = "OWNER_SERVICE_FEES";
    static DEFAULT_PRICING = "DEFAULT_PRICING";
    static DNU_BLOCKED = "DNU_BLOCKED";
    static STATUS = "STATUS";
    static BY_CLICK = "BY_CLICK";
    static CREATE_PROFILE = "CREATE_PROFILE";
    static I_ACCEPT_THE = "I_ACCEPT_THE";
    static TERMS_CONDITIONS = "TERMS_CONDITIONS";
    static PRIVACY_POLICY = "PRIVACY_POLICY";
    static OF_VHUB = "OF_VHUB";
    static ACCEPT_TERMS_CONDITIONS = "ACCEPT_TERMS_CONDITIONS";
    static DISCARD_PROFILE = "DISCARD_PROFILE";
    static REGISTER = "REGISTER";//Register
    static CHECK_MAILS = "CHECK_MAILS";//Please check your mails to complete your registration
    static JUNK_MAILS = "JUNK_MAILS"; //Please check your Junk or Spam mail if you did not receive an email from us
    static CALL_US_VIA_MAIL = "CALL_US_VIA_MAIL"; //You didn’t receive an email? Contact the vHub support via email or call us
    static OR = "OR"; //or
    static CONFIRM_PASSWORD = "CONFIRM_PASSWORD"; //Confirm Password
    static SUBMIT = "SUBMIT"; //Submit
    static USER_REGISTER_FORM = "USER_REGISTER_FORM"; //User Registration Form
    static LOG_APP = "LOG_APP"; //This will be username for logging into the application
    static V_HUB_AUTHOR_MAIL = "V_HUB_AUTHOR_MAIL"; //I authorize vHub to send me promotional mails
    static ERROR = "ERROR";//Error
    static PASSWORD_MIS_MATCH = "PASSWORD_MIS_MATCH";//Password and confirm password does not match

    //static NOTIFICATIONS = "NOTIFICATIONS";//Notifications


    /* INSPECTION COMPANY CODES */

    static COMPANY_NAME = "COMPANY_NAME";
    static INSPECTION_TYPE = "INSPECTION_TYPE";
    static USDOT = "USDOT";
    static USDOT_NUMBER = "USDOT_NUMBER"
    static CREATE_INSPECTION_COMPANY = "CREATE_INSPECTION_COMPANY";
    static INSPECTION_CREATED_SUCCESS = "INSPECTION_CREATED_SUCCESS";
    static INSPECTION_ORG_NAME = "INSPECTION_ORG_NAME";
    static INSPECTION_UPDATED = "INSPECTION_UPDATED";
    static COMPANY_DETAILS = "COMPANY_DETAILS";
    static ASSIGNED_ORGANIZATIONS = "ASSIGNED_ORGANIZATIONS";
    static INSPECTOR_FIRST_NAME = "INSPECTOR_FIRST_NAME";
    static INSPECTOR_LAST_NAME = "INSPECTOR_LAST_NAME";
    static PHONE_NUMBER = "PHONE_NUMBER";
    static ASSIGNED_LOCATIONS = "ASSIGNED_LOCATIONS";
    static INSPECTOR_TYPE = "INSPECTOR_TYPE"
    static USER_TYPE = "USER_TYPE"
    static CREATE_USER = "CREATE_USER"
    static ASSIGNED_BY = "ASSIGNED_BY"
    static ASSIGNED_DATE = "ASSIGNED_DATE"
    static ACCESS_INSTRUCTIONS = "ACCESS_INSTRUCTIONS"
    static ACTIONS = "ACTIONS"
    static ORGANIZATION_ADDED_SUCCESSFULLY = "ORGANIZATION_ADDED_SUCCESSFULLY"

    /* INTERCHANGE APPLICATION CODES */

    static INTERCHANGE_APPLICATION_SCREEN = "Interchange Application";
    static INTERCHANGE_APPLICATION = "INTERCHANGE_APPLICATION";
    static DOCUMENT_ID = "DOCUMENT_ID";
    static ENTRY_INSPECTION_ID = "ENTRY_INSPECTION_ID";
    static EXIT_INSPECTION_ID = "EXIT_INSPECTION_ID";
    static INTERCHANGE_DOCUMENT_NUMBER = "INTERCHANGE_DOCUMENT_NUMBER";
    static REFERENCE_NUMBER = "REFERENCE_NUMBER";


    static CREATE_INSPECTION_DOCUMENT = "CREATE_INSPECTION_DOCUMENT";
    static DOCUMENT_STATUS = "DOCUMENT_STATUS";
    static LOAD_STATUS = "LOAD_STATUS";
    static MANIFEST_NUMBER = "MANIFEST_NUMBER";
    static TE_BOND_NUMBER = "TE_BOND_NUMBER";
    static SEAL_NUMBER = "SEAL_NUMBER"
    static OWNER_COMPANY_CONTACT = "OWNER_COMPANY_CONTACT";
    static EQUIPMENT_CARRIER = "EQUIPMENT_CARRIER";
    static CARRIER_DOT = "CARRIER_DOT";
    static INTERCHANGE_ENTRY_DATE = "INTERCHANGE_ENTRY_DATE";
    static INTERCHANGE_EXIT_DATE = "INTERCHANGE_EXIT_DATE";
    static INTERCHANGE_ENTRY_LOCATION = "INTERCHANGE_ENTRY_LOCATION";
    static INTERCHANGE_EXIT_LOCATION = "INTERCHANGE_EXIT_LOCATION";
    static CARRIER_CONTACT = "CARRIER_CONTACT";
    static CARRIER_CONTACT_NUMBER = "CARRIER_CONTACT_NUMBER";
    static EQUIPMENT_OWNER_COMPANY = "EQUIPMENT_OWNER_COMPANY";
    static ENTRY_DATE = "ENTRY_DATE";
    static EXIT_DATE = "EXIT_DATE";
    static CREATED_DATE = "CREATED_DATE";
    static CREATED_TIME = "CREATED_TIME";
    static TRUCK_OWNER = "TRUCK_OWNER";
    static TRUCK_ID = "TRUCK_ID";
    static INSPECTOR_NAME = "INSPECTOR_NAME";
    static INSPECTION_LOCATION = "INSPECTION_LOCATION";
    static MANIFEST = "MANIFEST";
    static RELEASE_LOCATION = "RELEASE_LOCATION";
    static RECEIPT_LOCATION = "RECEIPT_LOCATION";
    static SETTLED_BY = "SETTLED_BY";
    static SETTLED_ON = "SETTLED_ON";
    static CLAIMED_AMOUNT = "CLAIMED_AMOUNT"
    static PAST_CLAIMS = "PAST_CLAIMS"
    static TRUCK_TIRE_AXLE = "TRUCK_TIRE_AXLE";
    static TRAILER_TIRE_AXLE = "TRAILER_TIRE_AXLE";
    static TRUCK_AXLE_TYPE = "TRUCK_AXLE_TYPE";
    static TRAILER_AXLE_TYPE = "TRAILER_AXLE_TYPE"
    static NUMBER_OF_AXLES = "NUMBER_OF_AXLES";

  //visual Inspection//

  static INSPECTION_ITEM = "INSPECTION_ITEM";
  static NOTE = "NOTE";
  static NEW_PASS = "NEW_PASS"





    /* REGISTRATION PAGE CODES */

    static LANGUAGE = "LANGUAGE";
    static DEPARTMENT = "DEPARTMENT";
    static CONTACT_NO = "CONTACT_NO";
    static ENTER_LAST_NAME = "ENTER_LAST_NAME";
    static LAST_NAME = "LAST_NAME";
    static ENTER_FIRST_NAME = "ENTER_FIRST_NAME";
    static FIRST_NAME = "FIRST_NAME";
    static CROSS_BORDER = "CROSS_BORDER";
    static OPR_US = "OPR_US";
    static OPR_CANADA = "OPR_CANADA";
    static ENTER_SOURCE = "ENTER_SOURCE";
    static SOURCE_OTHERS = "SOURCE_OTHERS";
    static SOURCE = "SOURCE";
    static ENTER_PHONE_NO = "ENTER_PHONE_NO";
    static PHONE_NO = "PHONE_NO";
    static ENTER_CITY = "ENTER_CITY";
    static CITY = "CITY";
    static COUNTRY = "COUNTRY";
    static ENTER_ADDRESS1 = "ENTER_ADDRESS1";
    static ADDRESS1 = "ADDRESS1";
    static JOIN_VHUB_AS = "JOIN_VHUB_AS";
    static ENTER_LEGAL_NAME = "ENTER_LEGAL_NAME";
    static LEGAL_NAME = "LEGAL_NAME";
    static COMPANY_NAME_DBA = "COMPANY_NAME_DBA";
    static COMPANY_REG_FORM = "COMPANY_REG_FORM";
    static ENTER_ZIP_CODE = "ENTER_ZIP_CODE";
    static ZIP_CODE = "ZIP_CODE";
    static STATE = "STATE";
    static ENTER_US_DOT_NO = "ENTER_US_DOT_NO";
    static US_DOT_NO = "US_DOT_NO";
    static ENTER_POSTAL_CODE = "ENTER_POSTAL_CODE";
    static POSTAL_CODE = "POSTAL_CODE";
    static PROVINCE = "PROVINCE";
    static CTQ = "CTQ";
    static TRANS_COMM_NO = "TRANS_COMM_NO";

    static MY_PROFILE = "MY_PROFILE";
    static PROVINCE_STATE = "PROVINCE_STATE";//Province/State
    static RENTER_STATUS = "RENTER_STATUS";//Renter Status
    static OWNER_STATUS = "OWNER_STATUS";//Owner Status
    static ORG_TYPE = "ORG_TYPE";//Organization Type
    static ORG_STATUS_UPDATED = "ORG_STATUS_UPDATED"; //Organization Status Updated Successfully
    static INACTIVATE = "INACTIVATE";//Inactivate
    static ACTIVATE = "ACTIVATE";//Activate
    static PREVENT_ORG = "PREVENT_ORG";//This action will prevent the Organization from using the vHub Application
    static ACCESS_ORG = "ACCESS_ORG";//This action will allow the Organization to access the vHub Application
    static INACTIVE_ORG = "INACTIVE_ORG";//Inactivate Organization
    static ACTIVE_ORG = "ACTIVE_ORG";//Activate Organization

    static ADD_USER = "ADD_USER";//Add User
    static ADD_ROLE = "ADD_ROLE";//Add Role
    static ADDRESS = "ADDRESS";//Address
    static GENERAL_INFORMATION = "GENERAL_INFORMATION";//General Information
    static ROLES_AND_PERMISSIONS = "ROLES_AND_PERMISSIONS";//Roles & Permissions
    static USERS = "USERS";//Users
    static UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";//Update Permissions
    static ROLE = "ROLE";//Role
    static ADD_NEW_USER = "ADD_NEW_USER";//Add New User
    static EDIT_USER = "EDIT_USER";//EDIT User
    static ROLE_NAME = "ROLE_NAME";//Role Name
    static ADD_NEW_ROLE = "ADD_NEW_ROLE";//Add New Role
    static RESEND_INVITATION_SUCCESS = "RESEND_INVITATION_SUCCESS";//Resend Invitation Successfully
    static USER_STATUS_UPDATED_SUCEESS = "USER_STATUS_UPDATED_SUCEESS";//User Status Updated Successfully
    static DO_WANT_RESEND_IVITATION = "DO_WANT_RESEND_IVITATION";//Do you want Resend Invitation?
    static USER_UPDATED_SUCEESS = "USER_UPDATED_SUCEESS";//User Updated Successfully
    static ROLE_CREATED = "ROLE_CREATED";//Role Created Successfully
    static PERMISSIONS_UPDATED = "PERMISSIONS_UPDATED";//Permission(s) Updated Successfully
    static LOGIN_ID = "LOGIN_ID";//Login Id
    static LAST_UPDATED_ON = "LAST_UPDATED_ON";//Last Updated On
    static LAST_UPDATED_BY = "LAST_UPDATED_BY";//Last Updated By
    static CREATION_DATE = "CREATION_DATE";//Creation Date
    static CREATED_BY = "CREATED_BY";//Created By
    static PERMISSION = "PERMISSION";//Permission
    static ENTER_ROLE_NAME = "ENTER_ROLE_NAME";//Enter Role Name
    static BROKER_NAME = "BROKER_NAME"
    static UPDATED_DATE = "UPDATED_DATE"
    static CREATE_BROKER = "CREATE_BROKER"
    static UPDATE_BROKER = "UPDATE_BROKER"
    static CREATE_CARRIER = "CREATE_CARRIER"
    static UPDATE_CARRIER = "UPDATE_CARRIER"
    static ENTER_CARRIER_NAME = "ENTER_CARRIER_NAME"

    /*Finance AND Insurance*/


    static CST_HST_NUMBER = "CST_HST_NUMBER";
    static QST_PST_NUMBER = "QST_PST_NUMBER";
    static BUSINESS_NUMBER = "BUSINESS_NUMBER";
    static BROWSE_FILE = "BROWSE_FILE";
    static W8_NUMBER = "W8_NUMBER";
    static W9_NUMBER = "W9_NUMBER";
    static INSURANCE_DETAILS = "INSURANCE_DETAILS";
    static INSURER_NAME = "INSURER_NAME";
    static INSURER_NUMBER = "INSURER_NUMBER";
    static INSURER_EXPIRATION = "INSURER_EXPIRATION";
    static CIVIL_COVERAGE = "CIVIL_COVERAGE";
    static NON_OWNED_COVERAGE = "NON_OWNED_COVERAGE";
    static CARRIERS_COVERAGE = "CARRIERS_COVERAGE";
    static CONTINGENT_CARGO = "CONTINGENT_CARGO";
    static DISABLE = "DISABLE";
    static EDIT = "EDIT";


    static BANK_DETAILS = "BANK_DETAILS";
    static FIN_INSTITUTION_NAME = "FIN_INSTITUTION_NAME";
    static FIN_INSTITUTION_NUMBER = "FIN_INSTITUTION_NUMBER";
    static FIN_INSTITUTION_ADDRESS = "FIN_INSTITUTION_ADDRESS";
    static COUNTRY_LOC = "COUNTRY_LOC";
    static CURRENCY = "CURRENCY";
    static ACC_NUMBER = "ACC_NUMBER";
    static RECIPIENT_NAME = "RECIPIENT_NAME";
    static TRANSIT = "TRANSIT";
    static ABA_ROUTING_NUMBER = "ABA_ROUTING_NUMBER";
    static TYPE = "TYPE";
    static AUTO_DEBIT = "AUTO_DEBIT";
    static DEFAULT_BANK = "DEFAULT_BANK";
    static CC_DETAILS = "CC_DETAILS";

    static ENGLISH = "ENGLISH";
    static FRENCH = "FRENCH";
    static SPANISH = "SPANISH";

    static LAST_ACCESSED = "LAST_ACCESSED";
    static CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

    static CARD_HOLDER_NAME = "CARD_HOLDER_NAME";
    static CARD_NO = "CARD_NO";
    static CARD_CVV = "CARD_CVV";
    static EXP_DATE = "EXP_DATE";
    static EQUIP_TYPE = "EQUIP_TYPE";
    static RENTER = "RENTER";
    static OWNER = "OWNER";
    static REPO_SERVICE = "REPO_SERVICE";
    static CAD = "CAD";
    static PERCENT = "PERCENT";
    static CLAIM_SERVICE_FEE = "CLAIM_SERVICE_FEE";
    static CLAIMS = "CLAIMS";
    static RENT_SERVICE_FEE = "RENT_SERVICE_FEE";

    static DAILY_RATE = "DAILY_RATE";
    static DIS_RATE = "DIS_RATE";
    static ENGINE_RATE = "ENGINE_RATE";

    static NETWORK_NAME = "NETWORK_NAME";//Network Name
    static CLOSED_BY = "CLOSED_BY";//Closed By
    static CLOSED_ON = "CLOSED_ON";//Closed On

    static ADD_BLOCK_NETWORK = "ADD_BLOCK_NETWORK";//Add Block Network
    static ADD_CLOSE_NETWORK = "ADD_CLOSE_NETWORK";//Add Close Network
    static ADD_PRIVATE_NETWORK = "ADD_PRIVATE_NETWORK";//Add Close Network
    static ADD_BLOCK_ORGS = "ADD_BLOCK_ORGS";//Add Block Orgs
    static ORG_API_KEY = "ORG_API_KEY";//Your Organization's API key
    static API_CONFIG = "API_CONFIG";//API Configuration
    static BUFFER_RENTALS = "BUFFER_RENTALS";//Buffer interval between Rentals
    static BUFFER_CONFIG_SERVER = "BUFFER_CONFIG_SERVER";//Buffer Configuration Interval
    static EQUIP_GEO_RESTICTIONS = "EQUIP_GEO_RESTICTIONS";//Equipment Geo Restrictions
    static CONDITIONS_RESTRICTIONS = "CONDITIONS_RESTRICTIONS";//Conditions & Restrictions
    static DOT_NUMBER = "DOT_NUMBER";//DOT Number
    static CLOSE_NETWORK = "CLOSE_NETWORK";//Close Network
    static PRIVATE_NETWORK = "PRIVATE_NETWORK";//Private Network
    static ACTION_REMOVE_NETWORK = "ACTION_REMOVE_NETWORK";//This action will remove Network
    static REMOVE_NETWORK = "REMOVE_NETWORK";//Remove Network
    static BLOCK_ORGANIZATION = "BLOCK_ORGANIZATION";//Block Ogranization
    static UNBLOCK_ORG = "UNBLOCK_ORG";//This action will allow the user to Unblock the Organization
    static UNBLOCK = "UNBLOCK";//Unblock
    static DOT_REMOVED_NETWORK = "DOT_REMOVED_NETWORK";//DOT Network removed Successfully
    static DOT_ADDED_NETWORK = "DOT_ADDED_NETWORK";//DOT Network added
    static ORG_BLOCK_SUCCESS = "ORG_BLOCK_SUCCESS";//Organization Blocked Successfully
    static DOT_ALREADY_EXIST = "DOT_ALREADY_EXIST";//DOT already exits
    static ORG_UNBLOCK_SUCCESS = "ORG_UNBLOCK_SUCCESS";//Organization Unblocked Successfully
    static REMOVE = "REMOVE";//Remove
    static RENEW = "RENEW";//Renew
    static GENERATE = "GENERATE";//Generate

    //DASHboard search//

    static UNIT_NUMBER = "UNIT_NUMBER";
    static ASSET_TYPE = "ASSET_TYPE";// Asset Type
    static GENERIC = "GENERIC";
    static BRAND = "BRAND";
    static MODEL = "MODEL";
    static AVAILABLE_TYPE = "AVAILABLE_TYPE";
    static AVAIL_FROM_DATE = "AVAIL_FROM_DATE";
    static AVAIL_TO_DATE = "AVAIL_TO_DATE";
    static AVAIL_DETAILS = "AVAIL_DETAILS";
    static EQUPMENT_TYPE = "EQUPMENT_TYPE"

    //Equipments//

    static VIN_NO = "VIN_NO"
    static RATING = "RATING"
    static AVAILABILITY = "AVAILABILITY"
    static DAMAGES = "DAMAGES"
    static GENERIC_SPECIFIC = "GENERIC_SPECIFIC";//Generic / Specific
    static ADD_EQUIPMENT = "ADD_EQUIPMENT";
    static GENERIC_EQUIP_RENTED = "GENERIC_EQUIP_RENTED";//When a generic equipment is rented, you will need to assign a specific equipement before the start of the reservation.
    static SIMILAR_EQUIPS = "SIMILAR_EQUIPS";//Display multiple similar equipment in a single listing to simplify the management of their availabilites.
    static AVAIL_THIS_EQUIP = "AVAIL_THIS_EQUIP";//availabilities for this specific equipment to available for the renter.
    static NEED_TO_ADD = "NEED_TO_ADD";//You will need to add
    static FOR_THE_RENTERS = "FOR_THE_RENTERS";//for the renters.
    static EQUIP_MADE_AVAIL = "EQUIP_MADE_AVAIL";//Will be the one made available
    static THE_SPECIFIC_EQUIP = "THE_SPECIFIC_EQUIP";//This specific equipment
    static TRUCK_NUMBER = "TRUCK_NUMBER"


    static ORG_NAME = "ORG_NAME"
    static REASON = "REASON"
    static CLAIMRAISEDBY = "CLAIMRAISEDBY"
    static BLOCKED_BY = "BLOCKED_BY"
    static BLOCKED_ON = "BLOCKED_ON"
    static CONDI_RESTRI = "CONDI_RESTRI"
    static EQUIP_GEO = "EQUIP_GEO"
    static BLOCKED_ORG = "BLOCKED_ORG"
    static ORGANISATION = "ORGANISATION"

    static COMPANY = "COMPANY"
    static MODAL = "MODAL"
    static EQUIP_NO_NAME = "EQUIP_NO_NAME"
    static START_DATE = "START_DATE"
    static END_DATE = "END_DATE"
    static RENTER_NAME = "RENTER_NAME"

    static DOC_NAME = "DOC_NAME"
    static VISIBILITY = "VISIBILITY"
    static UPLOAD = "UPLOAD"
    static PRIVT = "PRIVT"
    static RENTERS = "RENTERS"
    static PUBLIC = "PUBLIC"
    static GENERAL_DETAILS = "GENERAL_DETAILS"
    static UPLOAD_FILES = "UPLOAD_FILES"
    static YEAR = "YEAR"
    static AXLE_NO = "AXLE_NO"
    static TRAILER_LEN = "TRAILER_LEN"
    static LI_PLATE_NUMBER = "LI_PLATE_NUMBER"
    static ANN_INS_EXPIRE_DATE = "ANN_INS_EXPIRE_DATE"
    static TRACK_TYPE = "TRACK_TYPE"
    static SPECS = "SPECS"
    static REQ_SPECS = "REQ_SPECS"
    static AXLE_SPREAD = "AXLE_SPREAD"
    static TIRE_INF_SYS = "TIRE_INF_SYS"
    static SKIRTS = "SKIRTS"
    static PINTLE_HOOK = "PINTLE_HOOK"
    static HAZMAT_PLACARD = "HAZMAT_PLACARD"
    static SUSPENSION = "SUSPENSION"
    static FOOD_GRADE = "FOOD_GRADE"

    static NEW_EQUIPMENT = "NEW_EQUIPMENT"
    static SPECIFIC_EQUIP = "SPECIFIC_EQUIP"
    static GENERIC_EQUIP = "GENERIC_EQUIP"
    static TRAILERS = "TRAILERS"
    static REAR_DOOR = "REAR_DOOR"
    static SIDE_DOOR = "SIDE_DOOR"
    static ROOF = "ROOF"
    static FLOOR = "FLOOR"
    static INT_FINISH = "INT_FINISH"
    static LOGIS_POST_DIST = "LOGIS_POST_DIST"
    static DOUBLE_DECK_BEAMS = "DOUBLE_DECK_BEAMS"
    static LIFT = "LIFT"
    static LIFT_TYPE = "LIFT_TYPE"
    static LIFT_CAPACITY = "LIFT_CAPACITY"
    static TEMP_CONTROL = "TEMP_CONTROL"
    static UNIT_MAKE = "UNIT_MAKE"
    static UNIT_MODEL = "UNIT_MODEL"
    static UNIT_YEAR = "UNIT_YEAR"
    static MULTI_TEMP = "MULTI_TEMP"
    static EQUIP_PHOTO = "EQUIP_PHOTO"
    static ACTIVATION_INSTR = "ACTIVATION_INSTR"
    static DEACTIVATION_INSTR = "DEACTIVATION_INSTR"
    static PRICING = "PRICING"
    static GEN_SETT = "GEN_SETT";//General Settings"
    static PRIVATE = "PRIVATE"
    static DROP_FILES = "DROP_FILES"

    static SELECT_IMAGE = "SELECT_IMAGE"
    static INSTRUCTIONS = "INSTRUCTIONS"
    static INSTR_TEXT = "INSTR_TEXT"
    static DOCS_UPLOAD = "DOCS_UPLOAD"
    static RENT_TEXT = "RENT_TEXT"
    static PAYMENT_TERMS = "PAYMENT_TERMS"
    static PAYMENT_FEE = "PAYMENT_FEE"
    static DIST_RATE = "DIST_RATE"
    static ADD_FAV = "ADD_FAV"
    static EQUIP_PUBLIC_PAGE = "EQUIP_PUBLIC_PAGE"
    static EQUIP_DELETE = "EQUIP_DELETE"
    static EQUIP_EDIT = "EQUIP_EDIT"
    static GEN_INFO = "GEN_INFO"
    static DOCS = "DOCS"
    static RESERVATION = "RESERVATION"
    static HISTORY = "HISTORY"
    static ANN_MECH_EXPIRE_DATE = "ANN_MECH_EXPIRE_DATE"
    //static FLOOR_TYPE = "FLOOR_TYPE"
    static ADD_EMAIL_RENT_NOTI = "ADD_EMAIL_RENT_NOTI"
    static SEC_DEPO = "SEC_DEPO"
    static EQUIPMENT = "EQUIPMENT"
    static ASSIGN = "ASSIGN"
    static DRIVER_NAME = "DRIVER_NAME"
    static ASSI_DRIVER = "ASSI_DRIVER"
    static DETAILS = "DETAILS"
    static ACTIVATION = "ACTIVATION"
    static DEACTIVATION = "DEACTIVATION"
    static UNIT_CAPACITY = "UNIT_CAPACITY"

    static OWNER_SERVICE_FEE = "OWNER_SERVICE_FEE";//Owner Service Fee
    static UPDATED_SUCCESSFULLY = "UPDATED_SUCCESSFULLY";//Updated Successfully
    static FOR_RENTER = "FOR_RENTER";//For Renter
    static FOR_OWNER = "FOR_OWNER";//For Owner
    static REPOSITION_SERVICE = "REPOSITION_SERVICE";//Repositioning Service
    static VHUB_SERVICE_FEE = "VHUB_SERVICE_FEE";// The vHub Service fee is equal to the highest of
    static MIN_COST_AMOUNT = "MIN_COST_AMOUNT";//(i) the minimum cost amount and
    static PERCENT_SERVICE_AMOUNT = "PERCENT_SERVICE_AMOUNT";//(ii) the percentage of the positioning service amount

    static GST506_FORM = "GST506_FORM";//Download GST506 Form
    static FP_2506_FORM = "FP_2506_FORM";//Download FP2506 Form

    static HOUR = "HOUR";//HOUR
    static MAX_10_MB = "MAX_10_MB";//Max 10 mb
    static INSTRUCTIONS_REQUIRED = "INSTRUCTIONS_REQUIRED";//These instructions will be requested of the drivers to apply and confirm at the beginning and at the end of the rental. These instructions may also assist the driver if needed.
    static PLEASE_SELECT_IMAGE = "PLEASE_SELECT_IMAGE";//Please select an image
    static DRAG_DRAP_PHOTOS = "DRAG_DRAP_PHOTOS";//Drag & Drop here or Browse Photos
    static INTERIOR_FINISH = "INTERIOR_FINISH";//Interior Finish
    static SPECIFIC_REQUIRED = "SPECIFIC_REQUIRED";//Specifications Required
    static SPECIFICATIONS = "SPECIFICATIONS";//Specifications
    static ANNUAL_MECH_INSPECTION = "ANNUAL_MECH_INSPECTION";//Annual Mechanical Inspection Expiration Date
    static LICENSE_PLATE_NO = "LICENSE_PLATE_NO";//License Plate Number
    static LENGTH_OF_TRAILER = "LENGTH_OF_TRAILER";//Length of Trailer
    static NO_OF_AXELS = "NO_OF_AXELS";//Number of Axles
    static DO_YOU_WANT_REMOVE = "DO_YOU_WANT_REMOVE";//Do you want remove document?
    static REMOVED_SUCCESS = "REMOVED_SUCCESS";//Removed Successfully
    static NEW_DOCUMENT = "NEW_DOCUMENT";//New Document
    static DRAG_DRAP_FILES_BROWSE = "DRAG_DRAP_FILES_BROWSE";//Drag & drop your documents here or browse documents
    static EQUIP_NAME = "EQUIP_NAME";//Equipment Name
    static FLOOR_TYPE = "FLOOR_TYPE";//Floor Type
    static MASTER_DATA = "MASTER_DATA";// Master Data
    static INSPECTION_COMPANY = "INSPECTION_COMPANY";
    static SELECT = "SELECT";//Select
    static SECURE_DEPOSIT = "SECURE_DEPOSIT";//Security Deposit
    static ADDITIONAL_EMAILS = "ADDITIONAL_EMAILS";//Additional emails for renting notifications
    static RESERVATIONS = "RESERVATIONS";//Reservations
    static DOCUMENTS = "DOCUMENTS";//Documents

    static DO_U_WANT_DELETE = "DO_U_WANT_DELETE";//Do you want delete availability?
    static AVAIL_DELETE = "AVAIL_DELETE";//Availability Deleted Successfully
    static AVAIL_CREATED = "AVAIL_CREATED";//Availability Created Successfully
    static AVAIL_UPDATED = "AVAIL_UPDATED";//Availability Updated Successfully

    static REPOSITION = "REPOSITION";//Reposition
    static RENTAL = "RENTAL";//Rental
    static STARTS_AT = "STARTS_AT";//Starts at
    static CONTACT_NAME_AT = "CONTACT_NAME_AT";//Contact Name at
    static CONTACT_NUMBER_AT = "CONTACT_NUMBER_AT";//Contact Number at
    static ENDS_AT = "ENDS_AT";//Ends at
    static WILL_BE_CHARGED = "WILL_BE_CHARGED";//You will be charged for each excess day that the trailer is late.
    static AVAIL_EQUIP = "AVAIL_EQUIP";//Available equipment for this period
    static DAYS_ALLOWED = "DAYS_ALLOWED";//Days allowed for repositioning
    static LOAD_TYPE = "LOAD_TYPE";//Load Type
    static DEPARTRE_PARK_LOT = "DEPARTRE_PARK_LOT";//Departure Parking Lot
    static ARRIVAL_PARK_LOT = "ARRIVAL_PARK_LOT";//Arrival Parking Lot
    static ALLOW_RENTER = "ALLOW_RENTER";//Allow renter to make an offer for this availability
    static PAID_PRICE = "PAID_PRICE";//Paid Price to Carrier for Repositioning
    static ADDITIONAL_PRICE = "ADDITIONAL_PRICE";//Additional Price per Excess Days
    static EST_DISTANCE = "EST_DISTANCE";//Estimated excess days mileage
    static PRICE_PER_MILE = "PRICE_PER_MILE";//Price per mile
    static DAY = "DAY";//DAY
    static WEEK = "WEEK";//WEEK
    static MONTH = "MONTH";//MONTH
    static DEFAULT_PRICE = "DEFAULT_PRICE";//Default Price(s)
    static MILE = "MILE";//MILE
    static APPLY_RENTER = "APPLY_RENTER";//These are the rates that will apply for this rental. vHub will charge a fee in addition to these rates. Please refer to our Payment Terms for full details.
    static TAX_EXEMPTION_AVAIL = "TAX_EXEMPTION_AVAIL";//I have filled my tax exemptions OR I don’t have tax exemptions
    static TAX_EXEMPTION_ACCEPT = "TAX_EXEMPTION_ACCEPT";//You must accept the vHub Tax Exemptions
    static DELETE = "DELETE";//Delete
    static CREATE = "CREATE";//Create
    static CREATE_AND_BOOK = "CREATE_AND_BOOK";//Create
    static ADD_NEW_AVAIL = "ADD_NEW_AVAIL";//Add New Availability

    static FP_2506_FORM_ENGLISH = "FP_2506_FORM_ENGLISH";//Download FP2506 English Form
    static FP_2506_FORM_FRENCH = "FP_2506_FORM_FRENCH";//Download FP2506 French Form
    static DISTANCE_RATES = "DISTANCE_RATES";//Distance Rates
    static DAILY_RATES = "DAILY_RATES";//Daily Rates
    static WEEKLY_RATES = "WEEKLY_RATES";//Weekly Rates
    static MONTHLY_RATES = "MONTHLY_RATES";//Monthly Rates
    static CONFIRM_RESERVATION = "CONFIRM_RESERVATION";//Confirm Reservation
    static MAKE_AN_OFFER = "MAKE_AN_OFFER";//Make an Offer
    static ENGINE_UNIT_USAGE = "ENGINE_UNIT_USAGE";//Engine unit usage
    static DISTANCE_TO_TRAVEL = "DISTANCE_TO_TRAVEL";//Distance to travel
    static DATES = "DATES";//Dates
    static FULL_ADDRESS = "FULL_ADDRESS";//Full Address received after reservation
    static ARRIVAL = "ARRIVAL";//Arrival
    static DEPARTURE = "DEPARTURE";//Departure
    static YOU_WILL_EARN = "YOU_WILL_EARN";//You will earn
    static EXCESS_DAILY_TOTAL = "EXCESS_DAILY_TOTAL";//Excess daily total
    static DAYS_REPOSITION = "DAYS_REPOSITION";//day(s) for repositioning
    static PAID_PRICE_CARRIER = "PAID_PRICE_CARRIER";//Paid price to carrier making the move
    static FULL_ADDR_RECEIVE = "FULL_ADDR_RECEIVE";//Full Address received after reservation
    static DEP_ARRIVAL = "DEP_ARRIVAL";//Departure - Arrival
    static RESERVATION_INFO = "RESERVATION_INFO";//Reservation Information
    static RENTAL_TRANSACTIONS = "RENTAL_TRANSACTIONS";//for the conditions applicable to this rental transaction.
    static MASTER_LEASE_AGREEMENT = "MASTER_LEASE_AGREEMENT";//Master Lease Agreement
    static PLEASE_REFER_TO = "PLEASE_REFER_TO";//Please refer to
    static RESERVATION_CONDITION = "RESERVATION_CONDITION";//Reservation Conditions
    static H_DAY = "H_DAY";//h/day
    static KM_DAY = "KM_DAY";//km/day
    static DAYS = "DAYS";//day(s)
    static COST = "COST";//Cost
    static OFFER_DETAILS = "OFFER_DETAILS";//Offer Details
    static LOCATION = "LOCATION";//Location
    static AVAILABILITY_DETAILS = "AVAILABILITY_DETAILS";//Availability Details
    static OFFER_RECEIVED_FROM = "OFFER_RECEIVED_FROM";//Offer received from
    static OFFER_DETAILS_REPOSITION = "OFFER_DETAILS_REPOSITION";//Offer Details - Reposition
    static OFFER_DETAILS_RENTAL = "OFFER_DETAILS_RENTAL";//Offer Details - Rental
    static OFFER_CREATE_SUCCESS = "OFFER_CREATE_SUCCESS";//Offer created Successfully
    static ADD_FAVOR_SUCCESS = "ADD_FAVOR_SUCCESS";//Added Equipment to the Favorite
    static REMOVE_FAVOR_SUCCESS = "REMOVE_FAVOR_SUCCESS";//Removed Favorite successfully
    static RESERVATION_CONFIRM = "RESERVATION_CONFIRM";//Reservation Confirmed Successfully

    static ADD_NEW_DRIVER = "ADD_NEW_DRIVER";//Add New Driver
    static SAVE_ASSIGN = "SAVE_ASSIGN";//Save & Assign
    static GENERAL_SETTINGS = "GENERAL_SETTINGS";//General Settings
    static PRINT_PDF = "PRINT_PDF";//Print PDF
    static EMAIL_OPTIONAL = "EMAIL_OPTIONAL";//Email (Optional)
    static REPLACE_DRIVER = "REPLACE_DRIVER";//Replace Driver
    static ASSIGN_VIN = "ASSIGN_VIN";//Assign VIN
    static ASSIGN_EQUIPMENT_SUCCESS = "ASSIGN_EQUIPMENT_SUCCESS";//Assign Equipment to reservation Successfully
    static ASSIGN_DRIVER_SUCCESS = "ASSIGN_DRIVER_SUCCESS";//Assign driver to reservation Successfully

    static PROCESS_URL = "PROCESS_URL";//Process URL
    static REPLACE_VIN = "REPLACE_VIN";//Replace VIN

    static ACTION = "ACTION";//Action
    static AMOUNT = "AMOUNT";//Amount
    static INVOICE = "INVOICE";//Invoice
    static DATE = "DATE";//Date
    static ALL_YOUR_INVOICES = "ALL_YOUR_INVOICES";//All your invoices will be added in this section
    //static INVOICES = "INVOICES";//Invoices
    static SAFER_SCORE_DUNS_NUMBER = "SAFER_SCORE_DUNS_NUMBER";//Saferscore Duns Number
    static SAFER_SCORE_LATEST_UPDATE = "SAFER_SCORE_LATEST_UPDATE";//Saferscore Latest Update
    static SAFER_SCORE_OUT_OF_SERVICE = "SAFER_SCORE_OUT_OF_SERVICE";//Saferscore Out of Service Date
    static SAFER_SCORE_RATING = "SAFER_SCORE_RATING";//Saferscore Safety Rating
    static SAFER_SCORE_OS = "SAFER_SCORE_OS";//Saferscore Operating System
    static CONTACT_PHONE = "CONTACT_PHONE";//Contact Phone
    static CONTACT_EMAIL = "CONTACT_EMAIL";//Contact Email
    static CONTACT_NAME = "CONTACT_NAME";//Contact Name
    static INSURENCE_PROOF = "INSURENCE_PROOF";//Insurance Proof
    static ALL_REIMBURSEMENT = "ALL_REIMBURSEMENT";//All your reimbursement requests will be added in this section
    static RENTER_INFORMATION = "RENTER_INFORMATION";//Renter Information
    static OWNER_INFORMATION = "OWNER_INFORMATION";//Owner Information
    static TOTAL_BEFORE_TAXES = "TOTAL_BEFORE_TAXES";//Total Before Taxes
    static SERVICE_FEE = "SERVICE_FEE";//Service Fee
    static DISTANCE = "DISTANCE";//Distance
    static DURATION = "DURATION";//Duration
    static PARTKING_LOT_ACCESS = "PARTKING_LOT_ACCESS";//Parking Lot Access Instructions
    static RESERVATION_DEACTIVATION = "RESERVATION_DEACTIVATION";//Reservation Deactivation
    static RESERVATION_ACTIVATION = "RESERVATION_ACTIVATION";//Reservation Activation
    static RESERVATION_CREATION = "RESERVATION_CREATION";//Reservation Creation
    static HOURS_DAY = "HOURS_DAY";//hours/day
    static KM = "KM";//km
    static AVAILABLE = "AVAILABLE";//Available
    static AVAILABLE_VIEW_TYPE = "AVAILABLE_VIEW_TYPE";//Available View Type

    static CARRIER = "CARRIER";//Carrier 
    static HOOK_KM = "HOOK_KM";//Hook (KM)
    static DROP_TO_TARGET_KM = "DROP_TO_TARGET_KM";//Drop to Target (KM)
    static TOTAL_MILES = "TOTAL_MILES";//Total miles
    static EST_DRIVE_HRS = "EST_DRIVE_HRS";//Est. Drive Hrs
    static REVENUE_PER_HOUR = "REVENUE_PER_HOUR";//Rev per Hour
    static REVENUE = "REVENUE";//Revenue
    static BEST_MATCHES = "BEST_MATCHES";//Best Matches
    static NEAR_MATCHES = "NEAR_MATCHES";//Near Matches
    static LENGTH = "LENGTH";//Length
    static MINIMUM = "MINIMUM";//Minimum
    static MAXIMUM = "MAXIMUM";//Maximum
    static CLEAR = "CLEAR";//Clear
    static APPLY = "APPLY";//Apply
    static SELECT_ALL = "SELECT_ALL";//Select All
    static DRY_VAN = "DRY_VAN";//Dry Van
    static PLATE_DRY_VAN = "PLATE_DRY_VAN";//Plate Dry Van
    static STORAGE_VAN = "STORAGE_VAN";//Storage Van
    static REEFER_VAN = "REEFER_VAN";//Reefer Van
    static HEATED_VAN = "HEATED_VAN";//Heated Van
    static FLAT_BED = "FLAT_BED";//Flatbed
    static DROP_DECK = "DROP_DECK";//Dropdeck
    static LOG = "LOG";//Log
    static SCRAP_METAL = "SCRAP_METAL";//Scrap Metal
    static RECYCLING = "RECYCLING";//Recycling
    static CHIP = "CHIP";//Chip
    static DUMP = "DUMP";//Dump
    static LOWBED = "LOWBED";//Lowbed
    static CHASIS = "CHASIS";//Chassis
    static TANK = "TANK";//Tank
    static TANK_CRUDE = "TANK_CRUDE";//Tank Crude
    static TANK_DRY_BULK = "TANK_DRY_BULK";//Tank Dry Bulk
    static TANK_PETROLEUM = "TANK_PETROLEUM";//Tank Petroleum
    static TANK_PROPANE = "TANK_PROPANE";//Tank Propane
    static TANK_CHEMICAL = "TANK_CHEMICAL";//Tank Chemical
    static OTHER = "OTHER";//Other
    static SPOTTER = "SPOTTER";//Spotter
    static MODEL_YEAR = "MODEL_YEAR";//Model Year
    static AXIES_COUNT = "AXIES_COUNT";//Axles Count
    static DEPARTURE_DATE = "DEPARTURE_DATE";//Departure Date
    static BOBTAIL_TO_PU = "BOBTAIL_TO_PU";//Bobtail to Pu (KM)
    static DAYS_ALLOWED_REP = "DAYS_ALLOWED_REP";//Days Allowed for Reposition
    static DEPARTURE_ADDR = "DEPARTURE_ADDR";//Departure Address
    static RESERVATION_TYPE = "RESERVATION_TYPE";//
    static RENTAL_SERVICE_FEE = "RENTAL_SERVICE_FEE";//Rental Service Fee
    static PARKING_LOT_NAME = "PARKING_LOT_NAME";//Parking Lot Name
    static HOURLY_RATE = "HOURLY_RATE";//Hourly Rate
    static WEEKLY_RATE = "WEEKLY_RATE";//Weekly Rate
    static MONTHLY_RATE = "MONTHLY_RATE";//Monthly Rate
    static NAME = "NAME";//Name
    static NOTIFICATION = "NOTIFICATION";//Notification
    static DESTINATION = "DESTINATION";//Designation
    static ENTER_MOBILE_NUMBER = 'ENTER_MOBILE_NUMBER'; // Enter Mobile Number
    static MOBILE_NUMBER = "MOBILE_NUMBER"; // Mobile Number
    static ENTER_DRIVER_NAME = "ENTER_DRIVER_NAME"; // Enter Driver Name
    static CREATE_DRIVER = 'CREATE_DRIVER'; // Create Driver
    static UPDATE_DRIVER = 'UPDATE_DRIVER'; // Update Driver
    static CREATE_PARKING = 'CREATE_PARKING'; // Create Parking Lot
    static UPDATE_PARKING = 'UPDATE_PARKING'; // Edit Parking Lot
    static ACCESS_INSTRUCTION_OPTIONAL = 'ACCESS_INSTRUCTION_OPTIONAL'; //Access Instruction (Optional)
    static HOURS_ACCESS_AVAILABLE = 'HOURS_ACCESS_AVAILABLE'; //24 hours Access Available
    static SELECTED_USERS = 'SELECTED_USERS'; // Selected users:
    static EMAIL_ALERT = 'EMAIL_ALERT'; // Email Alert
    static FILE_NOT_CSV = 'FILE_NOT_CSV'; //File type is not csv
    static SAMPLE_CSV_FILE = 'SAMPLE_CSV_FILE'; // Sample xlsx file
    static FILE_NAME = 'FILE_NAME'; // File Name:
    static TOTAL_KM = "TOTAL_KM";//Total Distance (KM)
    static SEL_EQUIPMENT = "SEL_EQUIPMENT";//Select Equipment
    static CREATE_EQUIP = "CREATE_EQUIP";//Create Equipment
    static CREATE_PARKING_LOT = "CREATE_PARKING_LOT";//Create Parking Lot
    static CREATE_AVAILABILITY = "CREATE_AVAILABILITY";//Create Availability
    static RE_LISTING_DELAY = "RE_LISTING_DELAY";//Re-listing Delay
    static APPLY_CLOSE = "APPLY_CLOSE";//Apply & Close
    static CLEAR_FILTER = "CLEAR_FILTER";//Clear Filter

    /* Insurance Module */

    static INSURANCE_PLANS = "INSURANCE_PLANS";//Insurance Plans
    static INSURANCE1_PLANS_RATE = "INSURANCE1_PLANS_RATE";//Insurance Rate Table
    static ADD_INSURANCE_PLAN = "ADD_INSURANCE_PLAN";// Create Insurance Plan
    static INSUR_POLICY_ID = "INSUR_POLICY_ID";//policy Id
    static INSURENCE_NAME = "INSURENCE_NAME";//Insurance Company Name
    static DEDUCTABLE = "DEDUCTABLE";//Deductible
    static LIMIT = "LIMIT";//Limit
    static INSURANCE_DESC = "INSURANCE_DESC";//Description
    static INSUR_COVERAGE_DOC = "INSUR_COVERAGE_DOC";//Terms & Conditions
    static BROCHURE_DOC = "BROCHURE_DOC";//Brochure Document
    static CREATE_INSURANCE_PLAN = "CREATE_INSURANCE_PLAN";//Create Insurance Plan
    static CREATE_INSURANCE_RATE_TABLE = "CREATE_INSURANCE_RATE_TABLE";//Create Rate Table
    static RATE_MODEL_YEAR = "RATE_MODEL_YEAR";//Model Year
    static DECLARED_VALUE = "DECLARED_VALUE";//Declared Value
    static RATE_PER_DAY = "RATE_PER_DAY";//Rate per day
    static RATE_TABLE = "RATE_TABLE";//Insurance Rate Table
    static INSURANCE_MODULE = "INSURANCE_MODULE";//Insurance Module
    static ENABLE_INSURANCE_MODULE = "ENABLE_INSURANCE_MODULE";//Enable Insurance Module
    static INSURANCE_PAID_BY_OWNER = "INSURANCE_PAID_BY_OWNER";//Insurance will be paid by the Owner
    static INSURANCE_PAID_BY_RENTER = "INSURANCE_PAID_BY_RENTER";//Insurance will be paid by the Renter
    static INSURANCE_PLAN_NAME = "INSURANCE_PLAN_NAME";//Insurance Policy Name
    static ADD_INSURANCE_PLAN_POLICY = "ADD_INSURANCE_PLAN_POLICY";//Add Insurance Policy
    static UPDATED_BY = "UPDATED_BY";//Updated By
    static UPDATED_ON = "UPDATED_ON";//Updated On
    static INSURANCE_POLICY_LIST = "INSURANCE_POLICY_LIST";//Insurance Plans
    static ESTIMATED_VALUE = "ESTIMATED_VALUE";//Estimated Value
    static BILL = "BILL";//Bill
    static ADD = "ADD";//Add
    static DO_YOU_WANT_REMOVE_POLICY = "DO_YOU_WANT_REMOVE_POLICY";//Do you want remove policy?
    static GEN_CONFIG_INSUR_SAVED = "GEN_CONFIG_INSUR_SAVED";//Insurance Policies are Updated Successfully
    static DO_YOU_WANT_REMOVE_RATE = "DO_YOU_WANT_REMOVE_RATE";//Do you want remove plan rate?
    static SELECT_INSURANCE_PLAN = "SELECT_INSURANCE_PLAN";//Select Insurance Plan
    static TWIC_REQUIRE = "TWIC_REQUIRE";//Twic Required
    static TWIC_ACCESS = "TWIC_ACCESS";//Twic Access
    static IS_TWIC_ACCESS = "IS_TWIC_ACCESS";//Can access TWIC Locations
    static TWIC_ACCESS_REQUIRED = "TWIC_ACCESS_REQUIRED";//This pickup location requires TWIC Access.
    static ESTIMATED_LOW_VALUE = "ESTIMATED_LOW_VALUE";//Estimated low value
    static ESTIMATED_HIGH_VALUE = "ESTIMATED_HIGH_VALUE";//Estimated high value
    static ERROR_INSURANCE_MSG = "ERROR_INSURANCE_MSG";//Please select the Insurance will be paid by the Owner or Insurance will be paid by the Renter or both options
    static ERROR_INSURANCE_POLICY = "ERROR_INSURANCE_POLICY";//Please add the insurance policies
    static INSURANCE_PLAN_TYPE = "INSURANCE_PLAN_TYPE";//Insurance Plan Type
    static ENABLE_LIABILITY_INSURANCE = "ENABLE_LIABILITY_INSURANCE";//Enable Liability Insurance
    static ENABLE_PHYSICA_DAMAGE_INSURANCE = "ENABLE_PHYSICA_DAMAGE_INSURANCE";//Enable Physical Damage Insurance
    static PLEASE_SELECT_INSURANCE_TYPE = "PLEASE_SELECT_INSURANCE_TYPE"; //Please select the Insurance Type
    static PAID_BY_OWNER = "PAID_BY_OWNER"; //Paid By Owner
    static PAID_BY_RENTER = "PAID_BY_RENTER"; //Paid By Renter
    static LIABILITY_PLAN = "LIABILITY_PLAN"; //Liability Plan

    static NOT_RELEVANT_PLANS = "NOT_RELEVANT_PLANS"; //Please remove the non-relevant plans from the grid
    static LIABILITY_INSURANCE_PLAN = "LIABILITY_INSURANCE_PLAN"; //Liability Insurance Plan
    static PHYSICAL_DAMAGE_INSURANCE_PLAN = "PHYSICAL_DAMAGE_INSURANCE_PLAN"; //Physical Damage Insurance Plan

    static REPOSITIONING = "REPOSITIONING";//Repositioning
    static RENTAL_DB = "RENTAL_DB";//Rental
    static FROM_LOCATION = "FROM_LOCATION";//From Location
    static TO_LOCATION = "TO_LOCATION";//To Location
    static DEPARTURE_DATE_DB = "DEPARTURE_DATE_DB";//"Departure Date
    static ARRIVAL_DATE_DB = "ARRIVAL_DATE_DB";//"Arrival Date
    static DEVIATION = "DEVIATION";//"Deviation
    static MI = "MI";//"MI
    static TOTAL_DRIVE_TIME = "TOTAL_DRIVE_TIME";//"Total Drive Time


    static REPOSITIONS = "REPOSITIONS";//Repositions
    static TO = "TO";//to
    static ON = "ON";//on
    static TOTAL_MILES_DB = "TOTAL_MILES_DB";//Total Miles
    static DOCUMENT = "DOCUMENT";//Select Document
    static ATTACHED_DOCUMENT = "ATTACHED_DOCUMENT";//Document

    static TILE_VIEW = "TILE_VIEW";//Tile View
    static GRID_VIEW = "GRID_VIEW";//Grid View
    static BOOK_NOW = "BOOK_NOW";//Book Now
    static CREATE_OFFER = "CREATE_OFFER";//Create Offer

    static ARRIVAL_ADDRESS = "ARRIVAL_ADDRESS";//Arrival Address
    static TOTAL_DISTANCE_KM = "TOTAL_DISTANCE_KM";//Total Distance (KM)
    static VIN = "VIN";//VIN
    static EXCESS_DAYS = "EXCESS_DAYS";//Excess Days
    static ACTUAL_REPOSITION_DAYS = "ACTUAL_REPOSITION_DAYS";//Actual Reposition Days
    static PRICE_PER_KM = "PRICE_PER_KM";//Price per km
    static ADDITIONAL_RATE = "ADDITIONAL_RATE";//Additional Rate
    static MOVE_RATE = "MOVE_RATE";//Move Rate
    static DISTANCE_RATE = "DISTANCE_RATE";//Distance Rate
    static DE_ACTIVATION_DATE = "DE_ACTIVATION_DATE";//Deactivation Date
    static ACTIVATION_DATE = "ACTIVATION_DATE";//Activation Date
    static CONTACT_NUMBER = "CONTACT_NUMBER";//Contact Number
    static RENTER_DETAILS = "RENTER_DETAILS";//Renter Details
    static OWNER_DETAILS = "OWNER_DETAILS";//Owner Details
    static GENERATE_PDF = "GENERATE_PDF";//Generate PDF
    static SEND_TO_PREXTRA = "SEND_TO_PREXTRA";//Save & Send to Prextra
    static NEXT_RESERVATION_PROCESS = "NEXT_RESERVATION_PROCESS";//Next Reservation Process
    static CUSTOMER_PO = "CUSTOMER_PO";//Customer PO
    static RENTER_PO = "RENTER_PO"
    static CUSTOMER_GL_COST = "CUSTOMER_GL_COST";//Customer G/L Cost Rate
    static LINE = "LINE";//Line
    static LINE_ITEM = "LINE_ITEM";//Line Item
    static TOTAL_DAYS = "TOTAL_DAYS";//Total Days
    static PAY_PER_INSURANCE = "PAY_PER_INSURANCE";//Per Day Insurance
    static TOTAL_DISTANCE = "TOTAL_DISTANCE";//Total Distance
    static ENGINE_UNIT_HOURS = "ENGINE_UNIT_HOURS";//Engine Unit Hours
    static VUB_APPROVAL = "VUB_APPROVAL";//vHub Approval
    static CR_DR = "CR_DR";//CR/DR

    static NOTES = "NOTES";//Notes
    static CUSTOMER_NOTES = "CUSTOMER_NOTES";//Customer Notes
    static SAVED_SUCCESSFULLY = "SAVED_SUCCESSFULLY";//Saved Successfully
    static SEND_PREXTRA = "SEND_PREXTRA";//Saved & send to Prextra

    static RESERVATION_STATUS = "RESERVATION_STATUS";//Reservation Status
    static RESERVATION_END_DATE = "RESERVATION_END_DATE";//Reservation End Date
    static RESERVATION_START_DATE = "RESERVATION_START_DATE";//Reservation Start Date
    static VIN_NUMBER = "VIN_NUMBER";//VIN Number
    static RENTER_ORGANIZATION = "RENTER_ORGANIZATION";//Renter Organization
    static OWNER_ORGANIZATION = "OWNER_ORGANIZATION";//Owner Organization
    static RESERVATION_CODE = "RESERVATION_CODE";//Reservation Code
    static BILLS = "BILLS";//Bills
    static RECENT_UPDATE = "RECENT_UPDATE";//Recent update
    static USAGE_TYPE = "USAGE_TYPE";//Usage Type

    static AVAILABILITY_TYPE = "AVAILABILITY_TYPE";//Availability Type
    static IS_GENERIC = "IS_GENERIC";//Is Generic
    static AVAILABILITY_FROM_DATE = "AVAILABILITY_FROM_DATE";//Availability From Date
    static AVAILABILITY_TO_DATE = "AVAILABILITY_TO_DATE";//Availability To Date
    static PENDING_BILLING_RENTALS = "PENDING_BILLING_RENTALS";//Pending Billing - Rentals
    static PENDING_BILLING_REPOSITIONS = "PENDING_BILLING_REPOSITIONS";//Pending Billing - Repositions
    static DE_ACTIVATION_DUE = "DE_ACTIVATION_DUE";//Deactivation Due
    static ACTIVATION_DUE = "ACTIVATION_DUE";//Activation Due
    static INCOMPLETE_CUSTOMER_PROFILE = "INCOMPLETE_CUSTOMER_PROFILE";//Incomplete Customer Profile

    static DRIVER_SAMPLE_CSV_FILE = "DRIVER_SAMPLE_CSV_FILE";//Driver - Sample CSV file
    static IMPORT = "IMPORT";//Import
    static RATINGS = "RATINGS";//Ratings
    static NEW_AVAILABILITY = "NEW_AVAILABILITY";//New Availability
    static SPECIFIC_EQUIPS = "SPECIFIC_EQUIPS";//This specific equipment Will be the one made available for the renters.
    static MULTIPLE_SIMILAR_EQUIPS = "MULTIPLE_SIMILAR_EQUIPS";//Display multiple similar equipment in a single listing to simplify the management of their availabilites.
    static AVAIL_DATES = "AVAIL_DATES";//Availabilities will be automatically reallocated if a reservation is made on a subset of the dates
    static PHYSICAL_DAMAGE_PLAN = "PHYSICAL_DAMAGE_PLAN"; //Physical Damage Plan
    static INSURANCE = "INSURANCE";//Insurance
    static PRICING_UPDATE = "PRICING_UPDATE";//Pricing Updates will only affect new reservations.
    static DOCS_DELETE_SUCCESS = "DOCS_DELETE_SUCCESS";//Document Deleted Successfully
    static UNABLE_TO_DELETE_DOCS = "UNABLE_TO_DELETE_DOCS";//Unable to delete the doc
    static DOCS_UPLOAD_SUCCESS = "DOCS_UPLOAD_SUCCESS";//Document Uploaded Successfully
    static ALL = "ALL";//All
    static DAMAGE = "DAMAGE";//Damage
    static CONTEXT = "CONTEXT";//Context
    static ACTUAL_START_DATE = "ACTUAL_START_DATE";//Actual Start Date
    static ACTUAL_END_DATE = "ACTUAL_END_DATE";//Actual End Date

    static LIABILITY_INSURANCE_PLAN_PER_DAY = "LIABILITY_INSURANCE_PLAN_PER_DAY";//Liability Insurance Per Day
    static PHYSICAL_DAMAGE_PLAN_PER_DAY = "PHYSICAL_DAMAGE_PLAN_PER_DAY";//Damage Insurance Per Day
    static RESERVATION_ID = "RESERVATION_ID";//Reservation Id
    static RESERVATION_CANCEL = "RESERVATION_CANCEL";//Cancel Reservation
    static DO_U_WANT_CANCEL_RESERVATION = "DO_U_WANT_CANCEL_RESERVATION";//Are you sure Do you want Cancel Reservation?
    static RESERVATION_CANCEL_SUCCESS = "RESERVATION_CANCEL_SUCCESS";//Reservation Cancelled Successfully

    static PARKING_LOT_CODE_NAME = "PARKING_LOT_CODE_NAME";//Parking Lot Code Name
    static POINT_OF_INTEREST = "POINT_OF_INTEREST";//Point of Interest
    static PAY_TO_PARK = "PAY_TO_PARK";//Pay to Park
    static HOURS_24 = "HOURS_24";//24 hours

    static PHYSICAL_DAMAGE_PLAN_PAID_BY = "PHYSICAL_DAMAGE_PLAN_PAID_BY";//Damage Insurance Paid By
    static LIABILITY_PLAN_PAID_BY = "LIABILITY_PLAN_PAID_BY";//Liability Insurance Paid By
    static RESERVATIONS_START_DATE = "RESERVATIONS_START_DATE";//Reservation Start Date
    static RESERVATIONS_MADE_BY = "RESERVATIONS_MADE_BY";//Reservation made by
    static RESERVATIONS_END_DATE = "RESERVATIONS_END_DATE";//Reservation End Date

    static EQUIPMENT_RATINGS = "EQUIPMENT_RATINGS";//Equipment Ratings
    static OFFER_MADE_TO = "OFFER_MADE_TO";//Offer Made to
    static OFFER_MADE_ON = "OFFER_MADE_ON";//Offer Made on
    static OFFER_MADE_BY = "OFFER_MADE_BY";//Offer Made By
    static OFFER_RECEIVED_ON = "OFFER_RECEIVED_ON";//Offer Received On

    static DRIVERS_POSITION = "DRIVERS_POSITION";//Driver's Position
    static DEVICE_TIME = "DEVICE_TIME";//Device Time
    static MILES = "MILES";//miles
    static HODOMETER = "HODOMETER";//Hubodometer
    static LICENSE_PLATE = "LICENSE_PLATE";//License Plate
    static PASSENGER_SIDE_TIRE = "PASSENGER_SIDE_TIRE";//Passenger Side Tire
    static EXTERIOR_PASSENGER_SIDE = "EXTERIOR_PASSENGER_SIDE";//Exterior Passenger Side
    static VAN_INTERIOR = "VAN_INTERIOR";//Van Interior
    static REAR_EXTERIOR = "REAR_EXTERIOR";//Rear Exterior
    static DRIVER_SIDE_TIRES = "DRIVER_SIDE_TIRES";//Driver side Tires
    static DRIVER_SIDE_EXTERIOR = "DRIVER_SIDE_EXTERIOR";//Driver side Exterior
    static INSPECTION_PHOTOS = "INSPECTION_PHOTOS";//Inspection Photos
    static FRONT_EXTERIOR = "FRONT_EXTERIOR";//Front Exterior
    static REVIEW = "REVIEW";//Review
    static PROFF_OF_DELIVERY = "PROFF_OF_DELIVERY";//Proof of Delivery
    static ADD_PARKING = "ADD_PARKING";//Add Parking
    static SORT = "SORT";//Sort
    static COLUMNS_SHOW_HIDE = "COLUMNS_SHOW_HIDE";//Columns Show/Hide
    static CVS_DOWNLOAD = "CVS_DOWNLOAD";//CSV Download

    static AVAILABLE_COLUMNS = "AVAILABLE_COLUMNS";//Available Columns
    static SELECTED_COLUMNS = "SELECTED_COLUMNS";//Selected Columns
    static COLUMN_SORT = "COLUMN_SORT";//Column Sort
    static COLUMN_HEADING = "COLUMN_HEADING";//Column Heading
    static DESC = "DESC";//Desc
    static OPERATION = "OPERATION";//Operation
    static SEARCH_HERE = "SEARCH_HERE";//Search Here

    static PHYSICAL_DAMAGE_INSURANCE_PLAN_DETAILS = "PHYSICAL_DAMAGE_INSURANCE_PLAN_DETAILS";//Physical Damage Insurance Details
    static DOWNLOAD_FILE = "DOWNLOAD_FILE";//Download File
    static LIABILITY_INSURANCE_DETAILS = "LIABILITY_INSURANCE_DETAILS";//Liability Insurance Details
    static VIEW_DOCUMENT = "VIEW_DOCUMENT";//View Document
    static DAYS_ALLOWED_REPOSITION = "DAYS_ALLOWED_REPOSITION";//Days allowed for repositioning
    static ESTIMATE = "ESTIMATE";//Estimate
    static CHRONOLOGY = "CHRONOLOGY";//Chronology
    static PROFF_PICTURES = "PROFF_PICTURES";//Proof Pictures
    static RESPONSES = "RESPONSES";//Responses
    static CLAIM = "CLAIM";//Claim
    static TAX_AMOUNT = "TAX_AMOUNT";//Tax Amount
    static FOR = "FOR";//for
    static ADDED_ON = "ADDED_ON";//Added on
    static ADD_CLAIM = "ADD_CLAIM";//Add Claim
    static EDIT_CLAIM = "EDIT_CLAIM";
    static DESCRIPTION = "DESCRIPTION";//Description
    static ENTER_DESCRIPTION = "ENTER_DESCRIPTION";//Enter Description
    static RESPOND_TO_CLAIM = "RESPOND_TO_CLAIM";//Respond to Claim
    static CLAIM_TYPE = "CLAIM_TYPE";//Claim Type
    static CLAIM_REASON = "CLAIM_REASON";//Claim Reason
    static ENTER_CLAIM_AMOUNT = "ENTER_CLAIM_AMOUNT";//Please Enter claim amount including tax
    static TYPES = "TYPES";//Types
    static CLAIM_AMOUNT = "CLAIM_AMOUNT";//Claim Amount
    static VHUB_RENTAL_RATES = "VHUB_RENTAL_RATES";//These are the rates that will apply for this rental. vHub will charge a fee in addition to these rates. Please refer to our Payment Terms for full details.
    static ADDITIONAL_EMAILS_COMMA_SEPARATED = "ADDITIONAL_EMAILS_COMMA_SEPARATED";//Additional emails for renting notifications (Comma separated)
    static SECURE_DEPOSIT_RESERVATION = "SECURE_DEPOSIT_RESERVATION";//Security Deposit (Optional. vHub keeps the amount during reservation)
    static ENTER_AMOUNT = "ENTER_AMOUNT";//Enter Amount
    static DISCARD_THE_PROFILE = "DISCARD_THE_PROFILE";//Discarding the profile, will clear the data and navigate to login. Do you want continue?
    static REMOVE_BANK = "REMOVE_BANK";//Remove Bank
    static ADD_BANK = "ADD_BANK";//Add Bank
    static INSURANCE_POLICY_CREATED = "INSURANCE_POLICY_CREATED";//Insurance Policy Created Successfully
    static INSURANCE_POLICY_UPDATED = "INSURANCE_POLICY_UPDATED";//Insurance Policy Updated Successfully
    static DRIVER_INSTRUCTIONS = "DRIVER_INSTRUCTIONS";//These instructions will be requested of the drivers to apply and confirm at the beginning and at the end of the rental. These instructions may also assist the driver if needed.
    static ACTIVATION_INSTRUCTIONS = "ACTIVATION_INSTRUCTIONS";//Activation Instructions
    static VHUB1_0_UUID = "VHUB1_0_UUID";//vHub1.0 UUID
    static TAX_PERCENTAGE = "TAX_PERCENTAGE";//Tax Percentage
    static EST_EQUIP_VALUE = "EST_EQUIP_VALUE";//Equipment Estimated Value
    static ACTUAL_RATE_PER_DAY = "ACTUAL_RATE_PER_DAY";//Actual Rate Per Day
    static UPLOAD_EQUIP_DAMAGES = "UPLOAD_EQUIP_DAMAGES";//Upload Equipment Damages
    static DRAG_DRAP_FILES_DAMAGES = "DRAG_DRAP_FILES_DAMAGES";//Drag & drop your damages here or browse damages
    static ENTER_ESTIMATED_VALUE = "ENTER_ESTIMATED_VALUE";//Enter Estimated Value
    static SUCCESSFULLY_UPDATED_EST_VALUE = "SUCCESSFULLY_UPDATED_EST_VALUE";//Successfully updated estimated value
    static PLEASE_ENTER_MANDATORY_FIELDS = "PLEASE_ENTER_MANDATORY_FIELDS";//Please enter mandatory fields
    static PLEASE_SELECT_TERMS_CONDITIONS = "PLEASE_SELECT_TERMS_CONDITIONS";//Please select terms & conditions
    static VHUB_SERVICE_CHARGE = "VHUB_SERVICE_CHARGE";//vHub Service Charge
    static VIN_LENGTH_12 = "VIN_LENGTH_12";//The Vin number must be 12 characters minimum
    static EQUIP_DAMAGES = "EQUIP_DAMAGES";//Equipment Damages
    static DRAG_DRAP_DAMAGES = "DRAG_DRAP_DAMAGES";//browse damages
    static INVOICE_NUM = "INVOICE_NUM";//Invoice Number
    static REVISED_DE_ACTIVATION_DATE = "REVISED_DE_ACTIVATION_DATE";//Revised Deactivation Date
    static EXPORT_LIST = "EXPORT_LIST";//Export
    static GENERIC_RESERVATIONS = "GENERIC_RESERVATIONS";//VIN Assignment Pending
    static STATEMENT_OF_INCOME = "STATEMENT_OF_INCOME";//Statement of Income
    static STATEMENT_OF_INCOME_PAID = "STATEMENT_OF_INCOME_PAID";//The statements will be updated here at any time, when the reservation is completed and paid by the renter.
    static FROM_DATE = "FROM_DATE";//From Date
    static TO_DATE = "TO_DATE";//To Date
    static DATES_ARE_REQUIRED = "DATES_ARE_REQUIRED";//From Date and To Dates are required to update the Reservation
    static RESERVATION_DATES_UPDATED = "RESERVATION_DATES_UPDATED";//Reservation Dates are successfully updated
    static HOURS = "HOURS";//Hours/Day
    static ADD_RESPONSE = "ADD_RESPONSE";//Add Response
    static EDIT_RESPONSE = "EDIT_RESPONSE";//Edit Response
    static ENABLE_INTERCHANGE_MENU = "ENABLE_INTERCHANGE_MENU";//Enabled Interchange Menu
    static SUCCESSFULLY_BANK_DELETED = "SUCCESSFULLY_BANK_DELETED";//Bank Deleted Successfully
    static HIGHWAY_DETAILS_API = "HIGHWAY_DETAILS_API";//Highway API Details
    static INTERCHANGE_APP_MENU = "INTERCHANGE_APP_MENU";//Interchange Application
    static ANY_AVAILABLE = "ANY_AVAILABLE";//Any Available
    static PICK_ANY_AVAILABLE_TRAILER = "PICK_ANY_AVAILABLE_TRAILER";//Pick any available trailer
    static INTERCHANGE_MODULE = "INTERCHANGE_MODULE";//Interchange Module
    static ENABLE_INTERCHANGE_MODULE = "ENABLE_INTERCHANGE_MODULE";//Enable Interchange Module
    static PLATFORM_FEE_BY_OWNER = "PLATFORM_FEE_BY_OWNER";
    static PLATFORM_FEE_BY_RENTER = "PLATFORM_FEE_BY_RENTER";
    static PLATFORM_FEE_BY_RENTER_OWNER = "PLATFORM_FEE_BY_RENTER_OWNER";
    static ENABLE_INTERNL_USER = "ENABLE_INTERNL_USER";//Enable Interchange Module
    static ENABLE_INSPECTION_COMPANY = "ENABLE_INSPECTION_COMPANY";//Enable Interchange Module
    static ANY_AVAILABLE_MODULE = "ANY_AVAILABLE_MODULE";//Any Available Module
    static ENABLE_ANY_AVAILABLE_MODULE = "ENABLE_ANY_AVAILABLE_MODULE";//Enable Any Available Module
    static UPDATED_INTERCHANGE_SUCCESS = "UPDATED_INTERCHANGE_SUCCESS";//Successfully enabled interchange module
    static UPDATED_ANY_AVAILABLE_SUCCESS = "UPDATED_ANY_AVAILABLE_SUCCESS";//Successfully enabled any available
    static ENGINE_HOURS = "ENGINE_HOURS";//Engine Hours
    static INCOMPLETE_7DAYS_PROFILE = "INCOMPLETE_7DAYS_PROFILE";//New Customers Incomplete Profiles (7 days)
    static QUANTITY_UPDATED = "QUANTITY_UPDATED";//Successfully Quantity updated
    static AVL_DETAILS = "AVL_DETAILS";//Availability Details
    static VIN_OR_TRUCK_NO = "VIN_OR_TRUCK_NO";//VIN Number or Truck Number
    static ACTIVE_REPOSITIONS = "ACTIVE_REPOSITIONS";//active repositions
    static ACTIVE_RENTALS = "ACTIVE_RENTALS";//ACTIVE RENTALS
    static EXCESS_DAYS_RATE = "EXCESS_DAYS_RATE";//EXCESS_DAYS_RATE
    static CUSTOMER_MANAGER_NAME = "CUSTOMER_MANAGER_NAME";//CUSTOMER MANAGER NAME
    static CUSTOMER_MANAGER_EMAIL = "CUSTOMER_MANAGER_EMAIL";//CUSTOMER_MANAGER_EMAIL
    static SALES_AGENT_NAME = "SALES_AGENT_NAME";//salesAgentName
    static SALES_AGENT_EMAIL = "SALES_AGENT_EMAIL";//salesAgentEmail
    static SEND_DAILY_INVOICES = "SEND_DAILY_INVOICES";
    static IS_SEND_DAILY_INVOICES = "IS_SEND_DAILY_INVOICES";
    static EMAIL_STATUS = "EMAIL_STATUS";
    static EMAIL_SENT_AT = "EMAIL_SENT_AT";
    static REPROCESS_SUBMITTED_RESERVATIONS = "REPROCESS_SUBMITTED_RESERVATIONSOK";
    static CARRIER_DETAILS = "CARRIER_DETAILS";
    static CARRIER_NAME = "CARRIER_NAME";
    static CARRIER_MC_NUM = "CARRIER_MC_NUM";
    static CARRIER_US_DOT = "CARRIER_US_DOT";
    static DRIVER_NUMBER = "DRIVER_NUMBER";
    static LICENSE_NUMBER = "LICENSE_NUMBER";
    static TRUCK_LICENSE_NUMBER = "TRUCK_LICENSE_NUMBER";
    static INSURANCE_MAN = "INSURANCE_MAN";
    static INSURANCE_MAN_RENTAL = "INSURANCE_MAN_RENTAL";
    static EXTEND = "EXTEND";
    static TIME_ZONE = "TIME_ZONE";
    static ON_BEHALF_OF = "ON_BEHALF_OF";
    static RELEASE_INSPECTION = "RELEASE_INSPECTION";
    static NEXT = "Next"; //nEXT
    static ENTER_TRUCK_NUMBER = "ENTER_TRUCK_NUMBER";
    static ENTER_MANIFEST_NUMBER = "ENTER_MANIFEST_NUMBER";
    static ENTER_CAREER_NAME = "ENTER_CAREER_NAME";
    static ENTER_SEL_NUMBER = "ENTER_SEL_NUMBER";
    static COUNT = "COUNT";
    static INSPECTORNAME = "INSPECTORNAME";
    static VALIDATIONDATETIME = "VALIDATIONDATETIME";
    static RESERVATIONS_IMPORT = "RESERVATIONS_IMPORT";
    static INVOICE_CODE = "INVOICE_CODE";
    static IS_DEFAULT = "IS_DEFAULT";
    static PRIMARY_CONTACT = "PRIMARY_CONTACT";
    static BILLING_CYCLE = "BILLING_CYCLE";
    static PRORATE_MONTHLY_BILLING = "PRORATE_MONTHLY_BILLING";
    static MONTHLY_BILLING = "MONTHLY_BILLING"
    static OPEN_ACCOUNT = "OPEN_ACCOUNT"
    static CONSOLIDATED_INVOICE = "CONSOLIDATED_INVOICE"
    static WHITE_LABEL_INVOICE = "WHITE_LABEL_INVOICE";
    static ENABLE_WHITE_LABEL_INVOICE = "ENABLE_WHITE_LABEL_INVOICE";

    static OK = "OK";
    static FAIL = "FAIL";
    static YES = "YES";
    static NO = "NO";
    //static WEEK = "WEEK";
    //static MONTH = "MONTH";
    //static WEEKLY_RATES = "WEEKLY_RATES";
    //static MONTHLY_RATES = "MONTHLY_RATES";

    static MIN_PLATFORMFEE = 80;

    static getMessageText(msg: string): string {
        var LAN: any = null;

        let lang: any = "";
        if (localStorage.getItem("Language")) {
            lang = localStorage.getItem("Language");
        } else {
            lang = "en";
        }
        if (CommonUtil.SELECTED_LANGUAGE == "en") {
            LAN = CommonUtil.ENGLISH_JSON;
        } else if (CommonUtil.SELECTED_LANGUAGE == "fr") {
            LAN = CommonUtil.FRENCH_JSON;
        } else if (CommonUtil.SELECTED_LANGUAGE == "sp") {
            LAN = CommonUtil.SPANISH_JSON;
        }
        if (LAN) {
            return LAN[msg];
            /*if(msg == "EMAIL"){
                return LAN.EMAIL;
            }else if(msg == "PASS"){
                return LAN.PASSWORD;
            }else{
                return msg;
            }*/
        }

        return "";
    }
}
