import React from 'react';

const DownloadCSV = ({ data, fileName }:any) => {
  const convertToCSV = (objArray:any) => {
    const array:any = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const downloadCSV = () => {
    const csvData:any = new Blob([convertToCSV(data)], { type: 'text/csv' });
    const csvURL:any = URL.createObjectURL(csvData);
    const link:any = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
   <a style={{
    color: "#007bff",
    textDecoration: "none",
    backgroundColor: "transparent",
   }} onClick={downloadCSV}> Click on link to Download info file</a>
  );
}

export default DownloadCSV;