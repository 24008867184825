import React, { useEffect, useRef, useState } from "react";
import DetectDevice from "../../utils/DetectDevice";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import MGrid from "../../Components/MGrid";
import AGGrid from "../../Components/AGGrid";
import { NetworkManager } from "../../Components/NetworkManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { Events } from "../Languages/Events";
let selectedOrgId = ""
const ConsolidatedStatement = () => {
  const { isMobile } = DetectDevice();

  
  const [equipData, setEquipData] = useState<any>([]);
  const [orgData, setOrgData] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selCompanyObj, setSelCompanyObj] = useState<any>({});
  const gridRef = useRef<any>(null);
  const [eqpColumnDefs] = useState([
    {
      field: "batchNumber",
      headerName: "Batch Number",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
      // pinned: "left",
    },
    {
      field: "counts",
      headerName: "Total Invoice",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
      // pinned: "left",
    },{
      field: "lastUpdateDate",
      headerName: "Last Update Date",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "batchStatus",
      headerName: "Batch Status",
      resizable: true,
      width: 200,
      sortable: true,
      // cellRenderer:(params:any)=>{
      //   const onClickItem = (e:any)=>{ 
      //       downloadPDF();
      //   }
      //   return (<>
      //     {params?.value && <div className={"cellRenderHeader"}>
      //       <a><span onClick={onClickItem} className={"cellRender"}>{"Retry"}</span></a>
      //   </div>}
      //   </>
      //   )
      // },
      // pinned: "right",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "download",
      headerName: "download",
      resizable: true,
      width: 200,
      sortable: true,
      cellRenderer:(params:any)=>{
        const onClickItem = (e:any)=>{ 
            downloadPDF(params.data.batchId);
        }
        return (
          <div className={"cellRenderHeader"}>
            <a ><span onClick={onClickItem} className={"cellRender"} style={{fontSize:"20px"}}><i className="fa-solid fa-download"></i></span></a>
        </div>
        )
      },
      // pinned: "right",
    },   
  ]);
  const downloadPDF = async (batchId:any) => {
    if(CommonUtil.getSelectedOrgId()){
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        if (localStorage.getItem('token')) {
            var token = 'Bearer ' + localStorage.getItem('token')
            myHeaders.append('Authorization', token);
        }
        let qParam = selectedOrgId || CommonUtil.getSelectedOrgId();
        await fetch(NetworkManager.serverPath + `api/download/consolidatedStatements?saasOrgId=${qParam}&batchId=${batchId}`, {method:'GET', headers:myHeaders })
            .then((response:any) => {
                response.blob().then((blob:any) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `consolidatedStatements.pdf`;
                    a.click();
                });

            }).catch((error)=>{})
    }
}

  const getAllStatementsForOrg = () => {
    console.log(selCompanyObj);
    
    let qParam = selCompanyObj?.orgId || CommonUtil.getSelectedOrgId();
    let usrUrl = `api/reservation/findAllBatches?saasOrgId=${qParam}`;
    // let usrUrl = `api/reservation/getAllStatementsForOrg?saasOrgId=${qParam}`;
    NetworkManager.sendJsonRequest(
      usrUrl,
      "",
      "",
      "GET",
      true,
      onSuccessgetAllStatementsFororg,
      onFailgetAllStatementsFororg
    );
  };

  const onSuccessgetAllStatementsFororg = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          console.log(restData);
          setEquipData(restData);
          let tot = 0;
          restData?.map(({totalAmount}:any)=>tot+=Number(totalAmount));
          setTotalAmount(tot);
        }
      }
    }
  };

  const onFailgetAllStatementsFororg = () => {};

  useEffect(() => {
    if (CommonUtil.isRootUser()) {
      CommonUtil.getAllOrgs((data: any) => {
        let arr = [{ orgId: null, orgName: "All" }];
        setOrgData([...arr, ...data]);
        data?.map((ele:any)=>{
          if(CommonUtil.getSelectedOrgId() == ele.orgId){
            setSelCompanyObj(ele);
            console.log(CommonUtil.getSelectedOrgId(),ele);
          }
          
        })       
      });
    }else{
      let arr = [{ orgId: CommonUtil.getSelectedOrgId(), orgName: CommonUtil.getSelectedOrgName()}];
        setOrgData([...arr]);
        setSelCompanyObj({ orgId: CommonUtil.getSelectedOrgId(), orgName: CommonUtil.getSelectedOrgName()});           
    }
    
    getAllStatementsForOrg();
    document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    return () => {
      document.removeEventListener(
        Events.SWITCH_CUSTOMER_EVENT,
        onSwitchCustomer
      );
    };
  }, []);

  const onSwitchCustomer = (e: any) => {
    if (CommonUtil.isRootUser()) {
      Events.dispatchBreadCrumbEvent();
      CommonUtil.getAllOrgs((data: any) => {
        let arr = [{ orgId: null, orgName: "All" }];
        setOrgData([...arr, ...data]);
        if(data.orgId == CommonUtil.getSelectedOrgId())
          setSelCompanyObj(data);
      });
    }
    
      Events.dispatchBreadCrumbEvent();
    // getAllStatementsForOrg();
  };
  const onCompanyChange = (dataObj: any) => {
    setSelCompanyObj(dataObj);
    selectedOrgId = dataObj.orgid;
  };
  

  return (
    <>
      <div
        className="container-fluid container-fluid-padding"
        style={{ paddingTop: "15px" }}
      >
        <div className="row ">
            {/* <div className="col-12"> */}
                <div
                    className="col-md-4 col-3"
                    style={{
                    zIndex: 999,
                    // display: CommonUtil.isRootUser() ? "" : "none",
                    }}
                >
                    <span className="black-text css-16ihq8s">
                    {CommonUtil.getMessageText(Constants.COMPANY, "Company")}
                    </span>
                    <SingleSelectComp
                    dataSource={orgData}
                    selectedValue={selCompanyObj}
                    width={"100%"}
                    handleChange={onCompanyChange}
                    value={"orgId"}
                    label={"orgName"}
                    disabled={CommonUtil.isRootUser() ? false:true}
                    />
                </div>
                <div className="col-md-4 col-3 pt-4">
                        <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                            // onClick={filterReservations}
                                onClick={getAllStatementsForOrg}>
                            {CommonUtil.getMessageText(Constants.SEARCH,"Search")}
                        </button>
                        <span
                          style={{
                            fontSize: "0.7rem",
                            margin: "10px",
                            textAlign: "right",
                            position: "absolute",
                            right: "5%",
                          }}
                        >
                          Report data is generated for last cycle
                        </span>
                </div>
          {/* </div> */}
          
        </div>

        <div className="row">
          <div className={`col-12 ${isMobile ? "" : "pt-3"}`}>
            {isMobile ? (
              <MGrid
                id="OrgConGrid"
                cols={eqpColumnDefs}
                rows={equipData}
                pageSize={10}
                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                ref={gridRef}
                addNew={false}
              />
            ) : (
              <div
                className="ag-theme-alpine gridHeight"
                style={{ height: "calc(100vh - 250px)", width: "100%" }}
              >
                <AGGrid
                  id="OrgConGrid"
                  cols={eqpColumnDefs}
                  rows={equipData}
                  pageSize={500}
                  headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                  rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                  ref={gridRef}
                  addNew={false}
                />
              </div>
            )}
          </div>          
        </div>
        <div
              style={{
                fontSize: "1rem",
                textAlign: "right",
                margin:"20px"
              }}
            >
              {`Amount to be Paid: $ ${Number(totalAmount).toFixed(2)}`}
            </div>
      </div>
    </>
  );
};

export default ConsolidatedStatement;
