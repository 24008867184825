import React, {useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle, Ref} from 'react'
import {AgGridReact} from 'ag-grid-react'
import GridToolBar from './GridToolBar'
import {IAGrid, IGridToolBox} from './IInterfaces'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

var gridAPI: any = {}
var gridColumnApi: any = {}
//var params:any = {};

var strAgGrid = ''
var gridName = 'AgGrid';

const AGGrid = forwardRef((props: any, ref: Ref<IAGrid>) => {
    // console.log(this);

    const gridRef = useRef<IGridToolBox>(null)
    const [gAPI, setGridAPI] = React.useState<any>({})
    const [columnApi, setColumnApiI] = React.useState<any>({})

    const defColValues = useMemo(
        () => ({}), [],
    )

    const rowStyle = {background: 'black'}

    // set background colour on even rows again, this looks bad, should be using CSS classes
    const getRowStyle = (params: any) => {
        if (params.node.rowIndex % 2 === 0) {
            return {background: '#eef2f6'}
        }
    }

    useEffect(() => {
        //console.log(props);
    }, [])

    useImperativeHandle(ref, () => ({SayHi, columnFittoSize, getSelectedRows, getGridAPI}))

    /*const onGridReady = (params: any) => {
        //console.log(params);
        // params = params;
        strAgGrid = props.id + gridName
        gridAPI = params.api
        gridColumnApi = params.columnApi
        gridAPI.sizeColumnsToFit()
        gridColumnApi.autoSizeColumns()
        setGridAPI(params.api)
    }*/

    const onGridReady = (params:any)=>{
        //console.log(params);
        // params = params;
        strAgGrid = props.id+strAgGrid;
        strAgGrid = props.id+gridName;
        gridAPI = params.api;
        gridColumnApi = params.columnApi;
        //gridAPI.sizeColumnsToFit();
        //gridColumnApi.autoSizeColumns();
        setGridAPI(params.api);
        setColumnApiI(params.columnApi);

        /*setTimeout(()=>{
            if(localStorage[strAgGrid] && localStorage[strAgGrid] != "null"){
                var columnDefs = gridAPI.getColumnDefs();
                var colsdef:any = JSON.parse(localStorage[strAgGrid]);
                if(localStorage[strAgGrid] && localStorage[strAgGrid] != "null"){
                    var columnDefs = gridAPI.getColumnDefs();
                    var colsdef:any = JSON.parse(localStorage[strAgGrid]);

                    var colums:any = [];



                    for(var j:number = 0;j<colsdef.length;j++){
                        for(var i:number = 0;i<columnDefs.length;i++){
                            if(columnDefs[i].field == colsdef[j].field){
                                columnDefs[i].width = colsdef[j].width;
                                columnDefs[i].flex = 0;
                                if(colsdef[j].sort){
                                    columnDefs[i].sort = colsdef[j].sort;
                                }
                                if(colsdef[j].hide){
                                    columnDefs[i].hide = colsdef[j].hide;
                                }
                                break;
                            }
                        }
                    }
                    // console.log(columnDefs);
                    //gridAPI.setColumnDefs(colsdef);
                    //console.log(gridAPI.getColumnDefs());
                }else{
                    var columnDefs = gridAPI.getColumnDefs();
                    updateColumnDef(columnDefs);
                }
                var colums:any = [];
                for(var j:number = 0;j<colsdef.length;j++){
                    for(var i:number = 0;i<columnDefs.length;i++){
                        if(columnDefs[i].field == colsdef[j].field){
                            columnDefs[i].width = colsdef[j].width;
                            //columnDefs[i].flex = 0;
                            columnDefs[i].flex = 0;
                            if(colsdef[j].sort){
                                columnDefs[i].sort = colsdef[j].sort;
                            }
                            if(columnDefs[j].cellRenderer){
                                colsdef[j].cellRenderer = columnDefs[i].cellRenderer;
                            }
                            if(colsdef[j].hide){
                                columnDefs[i].hide = colsdef[j].hide;
                            }
                            break;
                        }
                    }
                }
                // console.log(columnDefs);
                //gridAPI.setColumnDefs(colsdef);
                //console.log(gridAPI.getColumnDefs());
            }else{
                var columnDefs = gridAPI.getColumnDefs();
                updateColumnDef(columnDefs);
            }

        });*/
    }

    function SayHi() {
        //console.log(gridAPI);
        setGridAPI(gridAPI)
        // setGridAPI(params.api);
    }

    const helloTest = () => {
        //  console.log(gridAPI);
    }

    const onFilterChange = (params: any) => {
        // console.log(params);
        if (gridRef.current) {
            gridRef.current.passColumnFilter(params)
        }
    }
    const getSelectedRows = (): any => {
        if (gAPI) {
            const selectedData = gAPI.getSelectedRows()
            return selectedData
        }
        return null
    }

    const getGridAPI = (): any => {
        return gAPI
    }

    const oViewChanged = (params: any) => {
        // console.log(params);
    }

    const onColumnResized = (params: any) => {
        // console.log(params.columnApi.columnModel.getColumnDefs());
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }
    const onColumnSort = (params: any) => {
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }

    const onColumnVisible = (params: any) => {
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }
    const onColumnMove = (params: any) => {
        updateColumnDef(params.columnApi.columnModel.getColumnDefs())
    }

    const columnFittoSize = () => {
        //console.log(gridAPI);
        if (gridAPI && gridAPI.sizeColumnsToFit) {
            gridAPI.sizeColumnsToFit()
            gridColumnApi.autoSizeColumns()
        }
    }

    let updateColumnDef = (colDef: any) => {
        localStorage[strAgGrid] = JSON.stringify(colDef)
    }

    let exportCSV = () => {
        if (gAPI) {
            gAPI.exportDataAsCsv()
        }
    }

    let onGridClear = () => {
        localStorage[strAgGrid] = null
        if (gAPI) {
            //console.log(props.cols);
            props.cols.forEach((col: any, idx: number) => {
                if (!col.sort) {
                    col.sort = null
                }
                if (!col.hide) {
                    col.hide = null
                }
            })
            gAPI.setColumnDefs(props.cols)
            var columnDefs = gAPI.getColumnDefs()
            updateColumnDef(gAPI)
            // gridAPI.suppressFocusAfterRefresh = true;
        }
    }

    let addRecord = () => {
        props.addNewRecord()
    }

    //console.log(defColValues, 'defColValues')
    useEffect(()=>{
        try{
            const gridData = JSON.parse(String(localStorage.getItem("GridData"))) || {};
            if(gridData){                       
                const data = gridData[props.id]
                if(props.cols?.length > 0){
                    columnApi.applyColumnState({
                            state: data,
                            applyOrder: true,
                        });
                }
            }
        }
        catch(e){}

    },[props.cols,columnApi])

    return (
        <React.Fragment>
            <div className={'gridHeight'}>
                {props.hideToolbar ? "":
                <GridToolBar ref={gridRef} gridId={props.id} rowData={props.rows} pageSize={props.pageSize}
                    columnDefs={props.cols} columnApi={columnApi} api={gAPI} onClear={onGridClear} exportCSV={exportCSV}
                    onClickAdd={addRecord} addNew={props.addNew ? true : false} tip={props.tooltip}
                />
                }
                <AgGridReact animateRows={true} enableFillHandle={true} onFilterChanged={onFilterChange} 
                    defaultColDef={defColValues} context={props.method} rowData={props.rows}
                    columnDefs={props.cols} getRowStyle={props.getRowStyle ? props.getRowStyle : getRowStyle}
                    rowHeight={props.rowHeight} headerHeight={props.headerHeight} enableCharts={true}
                    pagination={true} paginationPageSize={props.pageSize} suppressMovableColumns={true}
                    onGridReady={onGridReady} onViewportChanged={oViewChanged} onColumnResized={onColumnResized}
                    onSortChanged={onColumnSort} onColumnVisible={onColumnVisible} onColumnMoved={onColumnMove}
                    rowSelection={props.rowSelection ? props.rowSelection : 'single'}
                    rowMultiSelectWithClick={props.rowMultiSelectWithClick ? props.rowMultiSelectWithClick : false}
                    isRowSelectable={props.isRowSelectable} suppressRowClickSelection={props.suppressRowClickSelection ? true : false}
                    onFirstDataRendered={props.onFirstDataRendered} onRowSelected={props.onRowSelected?props.onRowSelected: null}
                />
            </div>
        </React.Fragment>
    )
})

export default AGGrid
