import React, { useState, useRef } from "react";
import { Table } from "react-bootstrap";
import NewEntryCTPATValues from "./NewEntryCTPATValues";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import Form from "react-bootstrap/Form";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";
import NewReeferValues from "./NewReeferValues";

const NewEntryReeferInsp = () => {
  let fileType: string = "";
  const { state, dispatch } = useReleaseInspectorContext();
  let qPath = CommonUtil.getInterchangeDocumentId();
  const fileref2 = useRef<HTMLInputElement>(null);
  const reeferInspection: any = state?.reeferInspection?.task
    ? [...state?.reeferInspection?.task]
    : [];
  const formType = [{}];

  const submitHandler = (event: any) => {
    event.preventDefault();
    const checkFuel = state?.reeferInspection?.task?.filter(
      (item: any) => item.taskName === "Fuel Level"
    )[0];
    if (!+checkFuel?.taskStatus || +checkFuel?.taskStatus === 0) {
      ToastManager.showToast("Please add Fuel level", "Error");
    } else {
      if (checkFuel?.attachments?.attachmentUploads?.length > 0) {
        dispatch({
          type: "reeferInspection",
          payload: {
            isCompleted: true,
          },
        });
      } else {
        ToastManager.showToast("Please upload attachments", "Error");
      }
    }
  };

  const onAddPictures = (cId: string, fType: string) => {
    fileType = fType;
    if (fileref2.current) {
      fileref2.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile2").click();
    }
  };

  const onLoginError = (err: any) => {
    console.log("Something went wrong");
  };

  let onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `attachments/${qPath}/${state?.eventId}/${state?.taskId}/${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  dispatch({
                    type: "reeferInspection",
                    payload: {
                      taskId: state?.taskID,
                      attachments: {
                        attachmentUploads: {
                          fullFilePath: imagePath,
                        },
                      },
                    },
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };

  console.log(state, "REEFER")

  return (
    <Form onSubmit={submitHandler}>
      <div>
        <div
          className="section"
          style={{
            paddingRight: "12px",
            height: "420px",
            minHeight: "420px",
            overflowY: "scroll",
          }}
        >
          <Table bordered hover>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>INSPECTION ITEM</th>
                <th style={{ width: "120px" }}>
                  <div className="d-flex">
                    <div>PASS</div>
                    <div style={{ marginLeft: "32px" }}>FAIL</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {reeferInspection?.map((data: any, i: number) => {
                let addOrder = {
                  ...data,
                };
                addOrder.order = i;
                return (
                  <NewReeferValues
                    data={addOrder}
                    key={data.taskId}
                    onAddPictures={onAddPictures}
                  />
                );
              })}
            </tbody>
            <input
              type="file"
              ref={fileref2}
              className="custom-file-doc-input"
              multiple={false}
              id="inputGroupFile2"
              accept="image/*;pdf/*"
              style={{ display: "none" }}
              onChange={(e) => {
                onSelectFiles(e);
              }}
            />
          </Table>

          <div className="text-right">
            <button type="submit" className="btn btn-success btn-lg">
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default NewEntryReeferInsp;
