import { useEffect, useRef, useState } from "react";
import { Constants } from "../Pages/Languages/Constants";
import { DragNDrop } from "../utils/DragDrop";
import { CommonUtil } from "../utils/CommonUtil";
import ToastManager from "./ToastManager";
import BusyLoader from "./BusyLoader";
import { NetworkManager } from "./NetworkManager";
import ErrorRender from "../Pages/MasterData/ErrorRender";
import DownloadCSV from "./DownloadCSV";

const FileImport = (props: any) => {
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState<any>([]);

  const equipDragRef = useRef<any>(null);
  const dropFileObj = useRef<any>(null);
  const fileInputRef = useRef<any>(null);

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let dropDiv = document.getElementById("dropzone-span");
    let browseButton = document.getElementById(
      "drop-browse"
    ) as HTMLButtonElement;
    let dropMain = document.getElementById("dropzone-main") as HTMLDivElement;
    dropMain.style.backgroundColor = "#c0c0c0";
    if (browseButton) {
      browseButton.classList.add("d-none");
    }
    if (dropDiv) {
      dropDiv.innerHTML = "Drop file.";
      dropDiv.style.color = "#fff";
    }
  };

  const resetDrag = () => {
    let dropDiv = document.getElementById("dropzone-span");
    if (dropDiv) {
      dropDiv.innerHTML = "Drag & Drop file here OR";
      dropDiv.style.color = "black";
    }
    let browseButton = document.getElementById(
      "drop-browse"
    ) as HTMLButtonElement;
    if (browseButton) {
      browseButton.classList.remove("d-none");
    }
    let dropMain = document.getElementById("dropzone-main") as HTMLDivElement;
    dropMain.style.backgroundColor = "#fff";
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      let splitType = files?.[0]?.name?.split(".");
      if (splitType.pop() === "csv") {
        dropFileObj.current = files[0];
        setFileName(files[0].name);
      } else {
        ToastManager.showToast("File type is not csv", "Info");
        setFileName("");
      }
    }
    resetDrag();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    resetDrag();
  };

  useEffect(() => {
    DragNDrop({
      drop: equipDragRef,
      handleDragOver,
      handleDrop,
      handleDragEnter,
      handleDragLeave,
    });
  }, []);

  useEffect(() => {
    if (props?.status === "save") {
      const impFile = async () => {
        await onImportFile();
      };
      impFile();
    }
  }, [props?.status]);

  const uploadDocument = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };
  const onSelectFiles = (event: any) => {
    if (event && event.target && event.target.files[0]) {
      let files = event?.target?.files;
      let splitType = files?.[0]?.name?.split(".");
      if (splitType.pop() === "csv") {
        dropFileObj.current = files[0];
        setFileName(files[0].name);
      } else {
        ToastManager.showToast(
          CommonUtil.getMessageText(
            Constants.FILE_NOT_CSV,
            "File type is not a csv"
          ),
          "Info"
        );
        setFileName("");
      }
    }
  };

  const onImportFile = async () => {
    if (CommonUtil.isRootUser())
      return ToastManager.showToast("Switch to customer.", "Info");
    let token = "Bearer " + localStorage.getItem("token");
    let myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("file", dropFileObj.current, fileName);

    let requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    BusyLoader.showLoader();
    fetch(NetworkManager.serverPath + `${props?.uploadUrl}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let restObj = JSON.parse(result);
        if(restObj?.errors?.length > 0){
            setErrors(restObj?.errors);
            if(props?.onRequestCall)
                props?.onRequestCall({...restObj,status:"failed"})
            // ToastManager.showToast(`import failed`, "Error");
            props?.onError();
        }else if (restObj.status === "failed") {
          // ToastManager.showToast(`import failed`, "Error");
          const eList = restObj?.rowList?.map((e: any) => {
            let obj = { ...e };
            // obj.errorMsg = e?.errorMsg || e.reservationCode;
            return obj;
          });
          fileInputRef.current.value = "";
          setErrors(eList);
          props?.onError();
        } else {
          ToastManager.showToast(`imported successfully`, "Info");
          setErrors([]);
          if(props?.onRequestCall)
            props?.onRequestCall(restObj)
        }
      })
      .catch((error) => {
        console.log("error", error);
        ToastManager.showToast(`import failed`, "Error");
      })
      .finally(() => {
        BusyLoader.hideLoader();
      });
  };

  return (
    <>
      <input
        name="file"
        type="file"
        id="inputImport"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple={false}
        onChange={(e) => {
          onSelectFiles(e);
        }}
        accept=".csv"
      />
      <div className="row mb-3 my-3">
        <div className="col-lg-6 " ref={equipDragRef}>
          <div className="dropzone dropzone-main" id="dropzone-main">
            <span id="dropzone-span">
              {CommonUtil.getMessageText(
                Constants.DRAG_DRAP_FILES,
                "Drag & Drop file here OR"
              )}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <button
              className="drop-browse browse-btn"
              id="drop-browse"
              onClick={uploadDocument}
            >
              {CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse Files")}
            </button>
            <div className="fallback"></div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-6 pt-3">
          <div className="row m-0">
            <div style={{ display: "block", width: "auto" }}>
              {CommonUtil.getMessageText(Constants.FILE_NAME, "File Name:")}{" "}
            </div>
            <div className="col-9 p-0 ml-3">
              <input
                type="text"
                className="d-inline-block pl-2 w-100"
                required
                value={fileName}
                contentEditable={false}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      {errors.length > 0 ? <ErrorRender errors={errors} includeColumn={props?.includeColumn} title="Details"/> : null}
      {errors.length > 0 && <DownloadCSV data={[{col1:"Row Number",col2:"Error",col3:"Reservation"},...errors]} fileName="errorfile" />}
    </>
  );
};

export default FileImport;
